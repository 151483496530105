import { Card, Col, List, Row, Typography } from "antd";
import React from "react";
import { Icon } from "@iconify/react";
import { commonRoles } from "../../utils/common";

const { Text } = Typography;
export const ProfileRoles: React.FC<{ userRoles: any[] }> = ({ userRoles }) => {
  const renderItem = (item: any) => {
    return (
      <List.Item>
        <Row gutter={8}>
          <Col>
            <Icon icon={item.icon} width="48" height="48" />
          </Col>
          <Col>
            <Text
              strong
              style={{ display: "block", fontWeight: "bolder", fontSize: 18 }}
            >
              {item.label}
            </Text>
            <div>
              <Text>{item.title}</Text>
            </div>
          </Col>
        </Row>
      </List.Item>
    );
  };
  return (
    <>
      <Card
        title="Rôles et accès"
        className="card__2"
        style={{ margin: "10px 0", width: "100%" }}
        styles={{ header: { background: "#F9FAFD" } }}
      >
        <List
          locale={{ emptyText: "Aucun accès" }}
          dataSource={commonRoles.filter(
            (p) => userRoles.findIndex((x) => x === p.value) !== -1
          )}
          renderItem={renderItem}
        />
      </Card>
    </>
  );
};
