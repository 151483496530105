import { Button, Card, Col, List, Rate, Row, Typography } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import React, { PropsWithChildren } from "react";
import { CreateForecast } from "./create";

export const WeekView: React.FC<
  PropsWithChildren<{
    week: number;
    month: number;
    year: number;
    items: any[];
    getForecats?: any;
    subject?: any;
    element?: any;
    monthWeek?: number;
    branchs?: any[];
  }>
> = ({
  items,
  month,
  monthWeek,
  week,
  year,
  getForecats,
  subject,
  element,
  branchs,
}) => {
  const [current, setCurrent] = React.useState<any>({});
  const [open, setOpen] = React.useState(false);

  function handleOpen(item: any) {
    console.log("ITEM", item);
    setCurrent(item);
    setOpen(true);
  }

  const renderItem = (item: any) => {
    return (
      <List.Item>
        {/**@ts-ignore */}
        <Card className="card" style={{ width: "100%" }}>
          <Row gutter={32} wrap={false} align="middle">
            <Col flex={1}>
              <Typography.Text
                strong
                style={{ fontSize: 18, textTransform: "uppercase" }}
              >
                {item.title}
              </Typography.Text>
              {/**@ts-ignore */}
              <Typography.Paragraph ellipsis={{ rows: 2 }}>
                {item.summary}
              </Typography.Paragraph>
            </Col>
            <Col>
              <div>
                <i></i>
                <span>{item?.subject?.title}</span>
              </div>
              <div>
                <i></i>
                <span>{item?.branch?.title}</span>
              </div>
            </Col>
            <Col>
              {/*<Rate disabled />*/}
              {/*<div>
                <Typography.Text>{`Etat de forme`}</Typography.Text>
    </div>*/}
              <Button
                onClick={() => handleOpen(item)}
              >{`Modifier la prévision`}</Button>
            </Col>
          </Row>
        </Card>
      </List.Item>
    );
  };

  return (
    <React.Fragment>
      <section>
        <PageHeader
          //onBack={() => window.history.back()}
          //title={subject?.title}
          extra={[
            <CreateForecast
              subject={subject}
              branchs={branchs}
              item={{
                month,
                year,
                week,
                monthWeek,
              }}
              init={getForecats}
            />,
          ]}
        ></PageHeader>
        <List dataSource={items} renderItem={renderItem} />
        <CreateForecast
          subject={subject}
          branchs={branchs}
          item={{
            month,
            year,
            week,
            monthWeek,
          }}
          init={getForecats}
          edit
          open={open}
          setOpen={setOpen}
          itemEditable={current}
        />
      </section>
    </React.Fragment>
  );
};
