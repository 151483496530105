import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { isEmpty } from "lodash";
import { Card } from "antd";

export const UserViewSubscriptions: React.FC<{ user: any }> = ({ user }) => {
  const teacher = useSelector((state: RootState) => state.teacher.current);
  const onUser = React.useCallback(() => {}, [user]);
  React.useEffect(() => {
    onUser();
  }, [onUser]);
  return <>{!isEmpty(teacher) && <Card className="card__2"></Card>}</>;
};
