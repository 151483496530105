import React from "react";
import { Card, List, Typography } from "antd";
import dateHelpers from "../../utils/helpers/dateHelpers";

const { Text } = Typography;

export const UserViewLogs: React.FC<{ user: any }> = ({ user }) => {
  const [data, setData] = React.useState<any[]>([]);
  const onUser = React.useCallback(() => {
    let list: any[] = user.auth?.historicals ?? [];
    list = [...list].sort((a, b) => {
      const dateComparison = a.date.localeCompare(b.date);
      return dateComparison;
    });
    setData(list.slice(0, 5));
  }, [user]);

  function getStatusLabel(s: string) {
    if (s === "connected") {
      return "Connexion au compte";
    } else if (s === "revoked") {
      return "Revocation de session";
    } else {
      return "Non spécifié";
    }
  }

  React.useEffect(() => {
    onUser();
  }, [onUser]);
  const renderItem = (item: any) => {
    return (
      <List.Item>
        <div>
          <Text style={{ display: "block", fontFamily: "Poppins-Light" }}>
            {dateHelpers.getDate(item.date, "dddd, DD MMM YYYY")}
          </Text>
          <Text style={{ display: "block", fontFamily: "Poppins-Bold" }}>
            {getStatusLabel(item.status)}
          </Text>
        </div>
      </List.Item>
    );
  };
  return (
    <>
      <Card
        style={{ margin: "10px 0" }}
        styles={{ header: { background: "#F9FAFD" } }}
        title="Logs de connexion"
        className="card__2"
      >
        <List dataSource={data} renderItem={renderItem} />
      </Card>
    </>
  );
};
