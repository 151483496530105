import { Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import React from "react";

export function TeacherView() {
  return (
    <Layout>
      <PageHeader title="" onBack={() => window.history.back()} />
    </Layout>
  );
}
