import {
  Avatar,
  Badge,
  Button,
  Col,
  Dropdown,
  Layout,
  Modal,
  Row,
  Typography,
} from "antd";
import { isEmpty, toUpper } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { appName } from "../../utils/helpers/title";
import { Icon } from "@iconify/react";
import { colorBackground } from "../../utils/theme/color";
import userHelper from "../../utils/helpers/userHelper";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";

const AdminNavbar: React.FC<{
  collapsed?: boolean;
  onCollapse?: () => void;
}> = ({ collapsed = false, onCollapse }) => {
  const user = useSelector((state: RootState) => state.user.profile);
  console.log("user", user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function logout() {
    userHelper.setLogout(dispatch);
    window.location.href = "/";
  }

  function handleLogout() {
    Modal.warning({
      centered: true,
      title: "Déconnexion",
      content: "Voulez-vous vous déconnecter?",
      okText: "Se déconnecter",
      cancelText: "Annuler",
      onCancel: () => window.history.back(),
      okCancel: true,
      onOk: logout,
    });
  }

  return (
    <header
      style={{
        position: "sticky",
        padding: "0.7rem",
        width: "100%",
        background: colorBackground,
        top: 0,
        zIndex: 3,
        borderRadius: 0,
      }}
      className="yc-car"
    >
      <Row
        align="middle"
        wrap={false}
        gutter={8}
        style={{ position: "relative" }}
      >
        <Col>
          <Button onClick={onCollapse} type="text">
            <AnimatePresence mode="wait" initial={false}>
              <motion.div
                key={collapsed ? "collapsed" : "expanded"}
                initial={{ opacity: 0, rotate: -90 }}
                animate={{ opacity: 1, rotate: 0 }}
                exit={{ opacity: 0, rotate: 90 }}
                transition={{ duration: 0.1 }}
                style={{ display: "inline-block" }}
              >
                <Icon
                  icon={!collapsed ? "ic:twotone-menu" : "ri:menu-2-fill"}
                  width="24"
                  height="24"
                />
              </motion.div>
            </AnimatePresence>
          </Button>
        </Col>
        <Col>
          <img
            src={require("../../assets/images/logo.png")}
            style={{ height: "100%", width: 70, display: "inline-block" }}
            alt=""
          />
        </Col>
        <Col>
          <div style={{ display: "none" }}>
            {`${toUpper(appName)} `}
            <span>ADMIN</span>
          </div>
        </Col>
        <Col flex={1}>
          <Row gutter={6} align="middle" justify="end" wrap={false}>
            <Col>
              <Dropdown
                trigger={["click"]}
                arrow
                menu={{
                  items: [
                    {
                      key: "light",
                      label: "Clair",
                      itemIcon: (
                        <Icon
                          icon="material-symbols:check-rounded"
                          width="24"
                          height="24"
                        />
                      ),
                      icon: (
                        <Icon icon="line-md:car-light" width="24" height="24" />
                      ),
                    },
                    {
                      key: "dark",
                      label: "Sombre",
                      icon: <Icon icon="circum:dark" width="24" height="24" />,
                    },
                  ],
                }}
              >
                <Button
                  type="text"
                  icon={
                    <Icon
                      icon="icon-park-solid:setting"
                      width="24"
                      height="24"
                    />
                  }
                ></Button>
              </Dropdown>
            </Col>
            <Col>
              <Badge count={10} size="small">
                <Button
                  type="text"
                  icon={<Icon icon="solar:bell-bold" width="24" height="24" />}
                ></Button>
              </Badge>
            </Col>
            <Col>
              <Dropdown
                trigger={["click"]}
                arrow
                menu={{
                  items: [
                    {
                      key: "profile",
                      label: "Profil et compte",
                      onClick: () => {
                        navigate("/admin/profile");
                      },
                    },
                    { key: "feedback", label: "Activités" },
                    { type: "divider" },
                    { key: "setting", label: "Paramètres" },
                    {
                      key: "logout",
                      label: "Déconnexion",
                      danger: true,
                      onClick: handleLogout,
                    },
                  ],
                }}
              >
                <Avatar src={user?.photo} style={{ cursor: "pointer" }}>
                  {!isEmpty(user?.photo) && (
                    <Icon
                      icon="iconamoon:profile-fill"
                      width="24"
                      height="24"
                    />
                  )}
                </Avatar>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
    </header>
  );
};
export { AdminNavbar };
