import { PresetColorType } from "antd/es/_util/colors";
import { LiteralUnion } from "antd/es/_util/type";
import { toUpper } from "lodash";

export const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const commonRoles: {
  label?: string;
  value: string;
  title?: string;
  icon?: string;
  color?: LiteralUnion<
    "default" | PresetColorType | "success" | "processing" | "error" | "warning"
  >;
}[] = [
  {
    label: "Administrateur Principal",
    value: "ADMIN_PRIMARY_ROLES",
    title: `Accès à tout, y compris gestion des rôles.`,
    icon: "mdi:primary-key",
    color: "blue",
  },
  {
    label: "Gestionnaire des Enseignants",
    value: "TEACHING_ROLES",
    title: `Accès limité à la gestion des enseignants, classes, cycles, et matières.`,
    icon: "mdi:teaching",
    color: "cyan-inverse",
  },
  {
    label: "Gestionnaire des Élèves et Parents",
    value: "USER_MANAGEMENT_ROLES",
    title: `Gestion des élèves, responsables d'élèves, et appréciations des enseignants.`,
    icon: "ic:baseline-school",
    color: "green-inverse",
  },
  {
    label: "Modérateur de Contenus",
    value: "EDITION_ROLES",
    title: `Validation et édition des contenus pédagogiques uniquement.`,
    icon: "arcticons:editor",
    color: "yellow-inverse",
  },
  {
    label: "Gestionnaire Financier",
    value: "FINANCE_ROLES",
    title: `Accès aux abonnements, paiements, et rapports financiers.`,
    icon: "arcticons:home-finance",
    color: "geekblue",
  },
  {
    label: "Invité",
    value: "ADMIN_ROLES",
    title: `Superviseur des activités`,
    icon: "fluent:guest-32-filled",
    color: "purple",
  },
];

export const commonGender = (v?: string) => {
  if (toUpper(v) === "M") {
    return "Homme";
  } else if (toUpper(v) === "F") {
    return "Femme";
  } else {
    return v;
  }
};

export function getSectorType(type: string) {
  switch (type) {
    case "cycle":
      return "Cycle";
    case "option":
      return "Option";
    case "section":
      return "Section";
    default:
      return "Filière";
  }
}
