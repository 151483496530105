import { Col, Row, Typography } from "antd";
import { isEmpty } from "lodash";
import React, { PropsWithChildren } from "react";

const { Text } = Typography;

export const ItemValue: React.FC<
  PropsWithChildren<{
    label?: string;
    value?: string;
    prefix?: React.ReactElement;
    inline?: boolean;
  }>
> = ({ label, value, prefix, children, inline }) => {
  return (
    <>
      {inline && (
        <Row gutter={4} align="middle">
          {!isEmpty(prefix) && <Col>{prefix}</Col>}
          {!isEmpty(label) && (
            <Col>
              <Text style={{ fontFamily: "Poppins-Bold" }}>{label}</Text>
            </Col>
          )}
          {!isEmpty(value) && (
            <Col>
              <Text>{value}</Text>
            </Col>
          )}
        </Row>
      )}
      {!inline && (
        <Row gutter={4} align="middle">
          {!isEmpty(prefix) && <Col>{prefix}</Col>}
          <Col flex={1}>
            {!isEmpty(label) && (
              <div>
                <Text style={{ fontFamily: "Poppins-Bold" }}>{label}</Text>
              </div>
            )}
            {!isEmpty(value) && (
              <div>
                <Text>{value}</Text>
              </div>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};
