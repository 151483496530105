import { routeInterface } from "../routes";
import { ForecastView } from "../screens/forecastView";
import { ForecatMonth } from "../screens/forecatMonth";
import { ForecastSubject } from "../screens/subject/forecast";

export const forecatRoutes: routeInterface[] = [
  {
    label: "previsions",
    layout: "/admin",
    menu: true,
    name: "forecatView",
    path: "/forecasts",
    active: true,
    component: ForecastView,
    font: "fa-duotone fa-books",
    category: "class",
    icon: "carbon:ibm-watson-knowledge-catalog",
    roles: ["ADMIN_PRIMARY_ROLES", "TEACHING_ROLES", "EDITION_ROLES"],
  },
  {
    label: "Gestionnaire de prévision",
    layout: "/admin",
    menu: false,
    name: "forecatMonth",
    path: "/forecasts/month",
    active: true,
    component: ForecatMonth,
    font: "fa-duotone fa-books",
    roles: ["ADMIN_PRIMARY_ROLES", "EDITION_ROLES"],
  },
  {
    label: "previsions",
    layout: "/admin",
    menu: false,
    name: "forecatSubjectCalendar",
    path: "/forecasts/:id/view",
    active: true,
    component: ForecastSubject,
    font: "fa-duotone fa-books",
    roles: ["ADMIN_PRIMARY_ROLES", "TEACHING_ROLES", "EDITION_ROLES"],
  },
];
