import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type dashboardType = {
  teacher: number;
  student: number;
  level: number;
  establishment: {
    all: number;
    school: number;
    university: number;
  };
  agent: number;
  course: number;
  lesson: number;
  video: number;
};
export interface userData {
  profile: any;
  current: any;
  list: any[];
  teachers: any[];
  lessons: any[];
  groups: any[];
  agents: any[];
  ready: boolean;
  dashboard: dashboardType;
  kpi: any[];
  charts: any[];
}

const initialState: userData = {
  profile: {},
  current: {},
  list: [],
  teachers: [],
  lessons: [],
  groups: [],
  agents: [],
  ready: false,
  charts: [],
  kpi: [],
  dashboard: {
    agent: 0,
    course: 0,
    establishment: {
      all: 0,
      school: 0,
      university: 0,
    },
    lesson: 0,
    level: 0,
    student: 0,
    teacher: 0,
    video: 0,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<any>) => {
      state.profile = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setUsers: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setUserTeachers: (state, action: PayloadAction<any[]>) => {
      state.teachers = action.payload;
    },
    setUserLessons: (state, action: PayloadAction<any[]>) => {
      state.lessons = action.payload;
    },
    setUserGroups: (state, action: PayloadAction<any[]>) => {
      state.groups = action.payload;
    },
    setAgents: (state, action: PayloadAction<any[]>) => {
      state.agents = action.payload;
    },
    setReady: (state, action: PayloadAction<boolean>) => {
      state.ready = action.payload;
    },
    setDashboard: (state, action: PayloadAction<dashboardType>) => {
      state.dashboard = action.payload;
    },
    setKPIData: (state, action: PayloadAction<any[]>) => {
      state.kpi = action.payload;
    },
    setChartData: (state, action: PayloadAction<any[]>) => {
      state.charts = action.payload;
    },
  },
});
export const {
  setUserProfile,
  setCurrentUser,
  setUsers,
  setUserGroups,
  setUserLessons,
  setUserTeachers,
  //setAgents,
  setReady,
  setDashboard,
  setChartData,
  setKPIData,
} = userSlice.actions;
export default userSlice.reducer;
