import React from "react";
import { colorPrimary } from "../../../utils/theme/color";
import { Button, Col, Modal, notification, Radio, Row } from "antd";
import { Icon } from "@iconify/react";
import store, { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { setCurrentUser, setUserProfile } from "../../../redux/userReducer";
import userService from "../../../services/userService";
import { useFormik } from "formik";
import { DlInput } from "../../../components/input";

export const UserUpdateProfile: React.FC<{ user: any }> = ({ user }) => {
  const profile = useSelector((state: RootState) => state.user.profile);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      gender: "",
    },
    onSubmit,
  });

  const onUser = React.useCallback(() => {
    formik.setFieldValue("firstName", user.firstName);
    formik.setFieldValue("lastName", user.lastName);
    formik.setFieldValue("gender", user.gender);
  }, [user, open]);
  React.useEffect(() => {
    onUser();
  }, [onUser]);

  async function onSubmit() {
    const { firstName, gender, lastName } = formik.values;
    const data = { firstName, lastName, gender };
    setLoading(true);
    await userService
      .update(user.uid, data)
      .then(async () => {
        await loadData(user.uid);
        notification.info({ message: "Informations modifiées avec succès" });
        setOpen(false);
        formik.resetForm();
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          message: "Erreur lors de la modification",
          description: error,
        });
      });
    setLoading(false);
  }

  async function loadData(id: string) {
    await userService
      .get(id)
      .then((response) => {
        store.dispatch(setCurrentUser(response.data));
        if (profile.uid === user.uid) {
          store.dispatch(setUserProfile(response.data));
        }
      })
      .catch(() => {});
  }

  function handleSubmit() {
    formik.handleSubmit();
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Button
        icon={<Icon icon="lucide:edit" width="24" height="24" />}
        style={{ color: user.uid === profile.uid ? colorPrimary : undefined }}
        disabled={user.uid !== profile.uid}
        onClick={handleOpen}
      >
        {`Modifier`}
      </Button>
      <Modal
        confirmLoading={loading}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Modifier"
        cancelText="Annuler"
        open={open}
        onClose={handleClose}
        title="Information de profil"
        centered
        //styles={{header:{background: "#F9FAFD"}}}
      >
        <Row gutter={8} style={{ flexDirection: "column" }}>
          <Col flex={1}>
            <DlInput
              formik={formik}
              label="Votre nom"
              name="lastName"
              type="text"
            />
          </Col>
          <Col flex={1}>
            <DlInput
              formik={formik}
              label="Votre prénom"
              name="firstName"
              type="text"
            />
          </Col>
          <Col flex={1}>
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              options={[
                { label: "Homme", value: "m" },
                { label: "Femme", value: "f" },
              ]}
              value={formik.values.gender}
              onChange={(e) => formik.setFieldValue("gender", e.target.value)}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};
