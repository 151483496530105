import React from "react";
import { UserViewProfile } from "./header";
import { useParams } from "react-router-dom";
import { range, toNumber } from "lodash";
import { Col, Row, Skeleton } from "antd";
import userService from "../../services/userService";
import store, { RootState } from "../../redux/store";
import { setCurrentUser } from "../../redux/userReducer";
import { UserViewStudent } from "./student";
import { UserViewTeacher } from "./teacher";
import { UserViewFollowers } from "./followers";
import { setCurrentStudent } from "../../redux/studentReducer";
import { setManagementList } from "../../redux/managementReducer";
import { setCurrentTeacher } from "../../redux/teacherReducer";
import { UserViewLogs } from "./logs";
import { UserViewSubscriptions } from "./subscriptions";
import { useSelector } from "react-redux";
import { ProfileRoles } from "../../components/profile/roles";

export function UserViewScreen() {
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const user = useSelector((state: RootState) => state.user.current);

  const onInit = React.useCallback(async () => {
    const id = params?.id ?? "";
    store.dispatch(setCurrentUser({}));
    store.dispatch(setCurrentStudent({}));
    store.dispatch(setManagementList([]));
    store.dispatch(setCurrentTeacher({}));
    await loadData(id);
    setLoading(false);
  }, [params]);

  React.useEffect(() => {
    onInit();
  }, [onInit]);

  async function loadData(id: string) {
    await userService
      .get(id)
      .then((response) => {
        store.dispatch(setCurrentUser(response.data));
      })
      .catch(() => {});
  }

  return (
    <>
      {!loading && (
        <div>
          <UserViewProfile user={user} />
          <Row gutter={8}>
            <Col xl={14} xxl={14} xs={24} sm={24} md={24} lg={14}>
              <UserViewStudent user={user} />
              <UserViewTeacher user={user} />
              <UserViewSubscriptions user={user} />
              {user?.auth && (
                <>
                  <ProfileRoles userRoles={user?.auth?.roles ?? []} />
                </>
              )}
            </Col>
            <Col xl={10} xxl={10} xs={24} sm={24} md={24} lg={14}>
              <UserViewLogs user={user} />
            </Col>
          </Row>
        </div>
      )}
      {loading &&
        range(0, 13).map((p) => (
          <Skeleton active={toNumber(p) % 4 === 0} key={`${p}_index`} />
        ))}
    </>
  );
}
