import React from "react";
import subjectService from "../../services/subjectService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { setAllSubjects } from "../../redux/subjectReducer";
import { useNavigate } from "react-router-dom";
import { BreadcrumbComponent } from "../../components/breadcrumb";

export function SubjectAllScreen() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const items = useSelector((state: RootState) => state.subject.all);
  const columns: ColumnsType<any> = [
    {
      dataIndex: "title",
      title: "Titre du cours",
      align: "left",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a?.title?.localeCompare(b?.title),
    },
    {
      dataIndex: "level",
      title: "Promotion",
      render(value, record, index) {
        return value?.title;
      },
      align: "left",
    },
    {
      title: "Branches",
      dataIndex: "branchs",
      render(value, record, index) {
        return value?.length;
      },
      align: "center",
    },
    {
      title: "Prévisions",
      dataIndex: "forecats",
      render(value, record, index) {
        return value?.length;
      },
      align: "center",
    },
  ];

  async function init() {
    if (items.length === 0) {
      setLoading(true);
    }
    await subjectService
      .getByKey(``)
      .then((response) => {
        const data = subjectService.getData(response);
        dispatch(setAllSubjects(data));
      })
      .catch(() => {});
    setLoading(false);
  }

  React.useEffect(() => {
    init();
  }, []);

  function onClick(item: any) {
    const uid = item.uid;
    navigation(`/admin/subjects/${uid}/view`);
  }

  return (
    <React.Fragment>
      <main>
        <div className="box yc-card">
          <BreadcrumbComponent title="Tous les cours"></BreadcrumbComponent>
          <Table
            dataSource={items}
            columns={columns}
            loading={loading}
            className="yc-table"
            onRow={(data: any) => {
              return {
                onClick: () => onClick(data),
              };
            }}
          />
        </div>
      </main>
    </React.Fragment>
  );
}
