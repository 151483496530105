import { Icon } from "@iconify/react";
import {
  Button,
  Divider,
  Drawer,
  Input,
  Radio,
  Switch,
  Typography,
} from "antd";
import React from "react";
import { useSelector } from "react-redux";
import store, { RootState } from "../../redux/store";
import { setAgentFilters } from "../../redux/agentReducer";
import { isEmpty } from "lodash";

export const AgentFilter = () => {
  const [open, setOpen] = React.useState(false);
  const filters = useSelector((state: RootState) => state.agent.filters);

  function handleFilter(props: {
    type: string;
    items: any[];
    value?: string;
    key?: string;
  }) {
    const { items, key, type, value } = props;
    let list = [...filters];
    list = list.filter((l) => l.type !== type);
    if (items.length > 0 || !isEmpty(value)) {
      list.push({ type, data: items, value, key });
    }
    store.dispatch(setAgentFilters(list));
  }

  function handleReset() {
    let list = [...filters];
    list = list.filter(
      (l) => ["page", "limit"].findIndex((x) => x === l.type) !== -1
    );
    store.dispatch(setAgentFilters(list));
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        icon={<Icon icon="mage:filter-fill" width="24" height="24" />}
      >
        {`Filtrer`}
      </Button>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        title="Filtrer les informations"
      >
        <div>
          <Input
            placeholder="Nom"
            value={filters.find((f) => f.type === "lastName")?.value}
            onChange={(e) =>
              handleFilter({
                items: [],
                key: "user.lastName",
                type: "lastName",
                value: e.target.value,
              })
            }
            style={{ margin: "4px 0" }}
          />
          <Input
            placeholder="E-mail"
            value={filters.find((f) => f.type === "email")?.value}
            onChange={(e) =>
              handleFilter({
                items: [],
                key: "user.email",
                type: "email",
                value: e.target.value,
              })
            }
            style={{ margin: "4px 0" }}
          />
          <Input
            placeholder="Téléphone"
            value={filters.find((f) => f.type === "phone")?.value}
            style={{ margin: "4px 0" }}
            onChange={(e) =>
              handleFilter({
                items: [],
                key: "user.phone",
                type: "phone",
                value: e.target.value,
              })
            }
          />
          <Divider />
          <Typography.Text
            style={{ display: "block" }}
          >{`Ordonner par`}</Typography.Text>
          <Radio.Group
            options={[
              { label: "Nom", value: "order[user.lastName]" },
              { label: "Prénom", value: "order[user.firstName]" },
            ]}
            onChange={(e) => {
              handleFilter({
                items: [],
                type: "order",
                value: e.target.value,
              });
            }}
            buttonStyle="solid"
            value={filters.find((f) => f.type === "order")?.value}
            optionType="button"
          />
          <Switch
            checkedChildren="ASC"
            unCheckedChildren="DESC"
            style={{ display: "block", margin: "5px 0" }}
            onChange={(e) =>
              handleFilter({
                items: [],
                type: "orientation",
                value: e ? "asc" : "desc",
              })
            }
            value={
              filters.find((f) => f.type === "orientation")?.value === "asc"
            }
          />
        </div>
        <div>
          <Button
            onClick={handleReset}
            icon={<Icon icon="system-uicons:reset" width="21" height="21" />}
          >
            {`Réinitialiser`}
          </Button>
        </div>
      </Drawer>
    </>
  );
};
