import { Card, Layout, List, Statistic } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Icon } from "@iconify/react";
import {
  colorAccent,
  colorPrimary,
  colorSecondary,
} from "../../utils/theme/color";

export function StatStructure() {
  const dashboard = useSelector((state: RootState) => state.user.dashboard);
  const [items, setItems] = React.useState<any[]>([]);
  const kpi = useSelector((state: RootState) => state.user.kpi);
  const colors = [colorPrimary, colorSecondary, colorAccent];
  const textColors = ["#FFF", "#000", "#FFF"];

  function init() {
    const elements: any[] = [];
    elements.push({
      title: "Enseignants",
      value: dashboard.teacher,
      icon: "fa-solid fa-person-chalkboard",
      color: "#098d03",
    });
    elements.push({
      title: "Etudiants",
      value: dashboard.student,
      icon: "fa-solid fa-screen-users",
      color: "#5c987a",
    });
    elements.push({
      title: "Ecole",
      value: dashboard.establishment.school,
      icon: "fa-solid fa-school",
      color: "#faad14",
    });
    elements.push({
      title: "Université",
      value: dashboard.establishment.university,
      icon: "fa-solid fa-graduation-cap",
      color: "#333",
    });
    setItems(elements);
  }

  React.useEffect(() => {
    init();
  }, []);

  const renderItem = (item: any, i: number) => {
    return (
      <List.Item>
        <Card className="card">
          <Statistic
            title={item.label}
            value={item.value ?? 0}
            valueStyle={{ color: colorPrimary, fontWeight: "bolder" }}
            prefix={
              <div
                className="card__2"
                style={{
                  background: colors[i % colors.length],
                  width: 50,
                  height: 50,
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  icon={item.icon}
                  style={{ color: textColors[i % textColors.length] }}
                  className={item.icon}
                />
              </div>
            }
          />
        </Card>
      </List.Item>
    );
  };

  return (
    <div>
      <List
        dataSource={kpi.filter((p) => p.type === "info") ?? []}
        renderItem={renderItem}
        grid={{
          gutter: 8,
          lg: 3,
          md: 2,
          xl: 3,
          xxl: 3,
          sm: 2,
          xs: 2,
        }}
      />
    </div>
  );
}
