import { Button, notification, Upload } from "antd";
import React from "react";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import store, { RootState } from "../../redux/store";
import config from "../../services/config";
import userService from "../../services/userService";
import { setCurrentUser, setUserProfile } from "../../redux/userReducer";

export function ProfileUpdatePhoto(props: {
  userId: string;
  onUploading?: (v: boolean) => void;
}) {
  const [fileList, setFileList] = React.useState<any[]>([]);
  const profile = useSelector((state: RootState) => state.user.profile);

  async function loadData(id: string) {
    await userService
      .get(id)
      .then((response) => {
        store.dispatch(setCurrentUser(response.data));
        if (profile.uid === props.userId) {
          store.dispatch(setUserProfile(response.data));
        }
      })
      .catch(() => {});
  }

  return (
    <>
      <Upload
        action={`${config.URL_API}/update/photo`}
        name="file"
        method="post"
        customRequest={async (options) => {
          const { file, onError, onSuccess } = options;
          const formData = new FormData();
          formData.append("file", file);
          formData.append("uid", props.userId);
          await userService
            .updatePhoto(formData)
            .then(async (response) => {
              const data: any = response.data as any;
              await loadData(props.userId);
              if (typeof onSuccess !== "undefined") onSuccess(data);
              if (typeof props.onUploading !== "undefined") {
                props.onUploading(false);
              }
              setFileList([]);
            })
            .catch((reason) => {
              const error = userService.getError(reason);
              notification.error({ message: error });
              if (typeof onError !== "undefined") {
                onError(reason, error);
              }
              if (typeof props.onUploading !== "undefined") {
                props.onUploading(false);
              }
            });
        }}
        listType="picture"
        fileList={fileList}
        multiple={false}
        showUploadList={false}
        onChange={(info) => {
          setFileList([info.file]);
          if (typeof props.onUploading !== "undefined") {
            props.onUploading(true);
          }
        }}
        headers={{
          "Content-Type": "multipart/form-data",
        }}
        beforeUpload={() => {
          return true;
        }}
      >
        <Button
          type="default"
          icon={<Icon icon="et:camera" width="25" height="17" />}
          shape="circle"
          disabled={props.userId !== profile?.uid}
          style={{
            visibility: props.userId !== profile?.uid ? "hidden" : undefined,
          }}
        ></Button>
      </Upload>
    </>
  );
}
