import React from "react";
import { MenuItem, SubMenu } from "react-pro-sidebar";
import { isEmpty } from "lodash";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { routeInterface } from "../../../routes";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

export const SidebarMenu: React.FC<{ menu: routeInterface }> = ({ menu }) => {
  const profile = useSelector((state: RootState) => state.user.profile);
  const [roles, setRoles] = React.useState<string[]>([]);

  const onProfile = React.useCallback(() => {
    setRoles(profile?.auth?.roles ?? []);
  }, [profile]);
  React.useEffect(() => {
    onProfile();
  }, [onProfile]);
  const size = 18;

  function getPath(layout: "/admin" | "/home", path?: string) {
    let url = "#";
    if (isEmpty(path)) {
      return url;
    }
    if (layout === "/admin") {
      url = "/admin";
    }
    if (path?.substring(0, 1) === "/") {
      url += path;
    } else {
      url += `/${path}`;
    }
    return url;
  }

  return (
    <>
      {(menu.items?.length ?? 0) === 0 ? (
        <MenuItem
          //suffix={<Badge variant="success">New</Badge>}
          icon={
            !isEmpty(menu.icon) ? (
              <Icon fontSize={size} icon={menu.icon} />
            ) : undefined
          }
          disabled={!menu.roles?.some((role) => roles.includes(role))}
          component={<Link to={`${getPath(menu.layout, menu.path)}`} />}
        >
          {menu.label}
        </MenuItem>
      ) : (
        <SubMenu
          label={menu.label}
          icon={
            !isEmpty(menu.icon) ? (
              <Icon fontSize={size} icon={menu.icon} />
            ) : undefined
          }
          /*suffix={
                  <Badge variant="danger" shape="circle">
                    6
                  </Badge>
                }*/
        >
          {menu.items?.map((p, i) => (
            <SidebarMenu menu={p} />
          ))}
        </SubMenu>
      )}
    </>
  );
};
