import { Card } from "antd";
import moment from "moment";
import React from "react";
import { AreaChart } from "../../components/chart/area";
import subscriptionService from "../../services/subscriptionService";
import dateHelpers from "../../utils/helpers/dateHelpers";
import ReactApexChart from "react-apexcharts";
import { colorPrimary, colorSecondary } from "../../utils/theme/color";

export function LastSubscription() {
  const [labels, setLabels] = React.useState<string[]>([]);
  const [data, setData] = React.useState<number[]>([]);
  const [months, setMonths] = React.useState<Date[]>([]);

  async function init() {
    const elements: any[] = [];
    await Promise.all(
      months.map(async (p: Date, index: number) => {
        const date1 = dateHelpers.getDate(p, "YYYY-MM-01") ?? "";
        const date = moment(p).add("month", 1).toDate();
        const date2 = dateHelpers.getDate(date, "YYYY-MM-01") ?? "";
        elements[index] = await getStudents(date1, date2);
      })
    );
    setData(elements);
  }

  async function getStudents(date1: string, date2: string) {
    let result: number = 0;
    const query = `createdAt[after]=${date1}&createdAt[strictly_before]=${date2}`;
    await subscriptionService
      .getByKey(query)
      .then((response) => {
        result = subscriptionService.getTotal(response);
      })
      .catch(() => {});
    return result;
  }

  React.useEffect(() => {
    const date = new Date();
    const items = [
      moment(date).add("month", -5).toDate(),
      moment(date).add("month", -4).toDate(),
      moment(date).add("month", -3).toDate(),
      moment(date).add("month", -2).toDate(),
      moment(date).add("month", -1).toDate(),
      date,
    ];
    const elements: string[] = [];
    items.forEach((p) => {
      if (moment(p).isSame(new Date(), "year")) {
        elements.push((dateHelpers.getDate(p, "MMMM") ?? "").toUpperCase());
      } else {
        elements.push(
          (dateHelpers.getDate(p, "MMMM YYYY") ?? "").toUpperCase()
        );
      }
    });
    setLabels(elements);
    setMonths(items);
  }, []);

  React.useEffect(() => {
    init();
  }, [months]);

  return (
    <Card className="card" style={{ marginTop: 10, marginBottom: 10 }}>
      {/*<AreaChart
                labels={labels}
                title="Derniers abonnements"
                data={data}
                color="#098d03"
            />*/}
      {data.length > 0 && (
        <div id="chart">
          <ReactApexChart
            options={{
              chart: {
                id: "realtime",
                height: 350,
                type: "line",
                animations: {
                  enabled: true,
                  //easing: 'linear',
                  dynamicAnimation: {
                    speed: 1000,
                  },
                },
                toolbar: {
                  show: false,
                },
                zoom: {
                  enabled: false,
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
                width: 1,
                colors: [colorPrimary, "red", "green"],
              },
              title: {
                text: "Derniers abonnements",
                align: "left",
              },
              markers: {
                size: 0,
              },
              xaxis: {
                //type: 'datetime',
                //range: XAXISRANGE,
                categories: labels,
              },
            }}
            series={[{ data, name: "Abonnements" }]}
            type="line"
            height={350}
          />
        </div>
      )}
      <div id="html-dist"></div>
    </Card>
  );
}
