import { Layout, Skeleton, Spin } from "antd";
import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import routes from "../routes";
import { NotFoundPage } from "./404";
import userHelper from "../utils/helpers/userHelper";
import { isEmpty, range } from "lodash";

const getRoutes = () => {
  const items = routes.filter(
    (p) => p.layout === "/home" && p.component != null
  );
  return items.map((prop, key) => {
    return <Route path={prop.path} element={<prop.component />} key={key} />;
  });
};

function AuthLayout() {

  const navigate = useNavigate();
  const [isVerified, setVerified] = React.useState(false);

  const onInit = React.useCallback(()=>{
    const user = userHelper.getData();
    if(!isEmpty(user)){
      navigate('/admin',{replace:true});
    }else{
      setVerified(true);
    }
  },[]);

  React.useEffect(()=>{
    onInit();
  },[])
  return (
    <>
    {!isVerified && (
      <Spin spinning tip="Attendez svp!!!">
        {
          range(0,12).map(p=>(
            <Skeleton active={p%3===0} key={`skeleton_${p}`} />
          ))
        }
      </Spin>
    )}
    {isVerified && (<Layout>
      <Routes>
        {getRoutes()}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Layout>)}
    </>
  );
}

export { AuthLayout };
