import React from "react";
import { useSelector } from "react-redux";
import store, { RootState } from "../redux/store";
import { UserViewProfile } from "./user/header";
import { UserViewLogs } from "./user/logs";
import { UserViewStudent } from "./user/student";
import { UserViewTeacher } from "./user/teacher";
import { UserViewSubscriptions } from "./user/subscriptions";
import { Col, Row, Skeleton } from "antd";
import { toNumber } from "lodash";
import { range } from "lodash";
import { setCurrentTeacher } from "../redux/teacherReducer";
import { setManagementList } from "../redux/managementReducer";
import { setCurrentStudent } from "../redux/studentReducer";
import { setCurrentUser } from "../redux/userReducer";
import userService from "../services/userService";
import { ProfileRoles } from "../components/profile/roles";

export function ProfileScreen() {
  const profile = useSelector((state: RootState) => state.user.profile);
  const user = useSelector((state: RootState) => state.user.current);
  const [loading, setLoading] = React.useState(true);

  const onUser = React.useCallback(async () => {
    const id = profile.uid;
    store.dispatch(setCurrentUser({}));
    store.dispatch(setCurrentStudent({}));
    store.dispatch(setManagementList([]));
    store.dispatch(setCurrentTeacher({}));
    await loadData(id);
    setLoading(false);
  }, [profile]);

  React.useEffect(() => {
    onUser();
  }, [onUser]);

  async function loadData(id: string) {
    await userService
      .get(id)
      .then((response) => {
        store.dispatch(setCurrentUser(response.data));
      })
      .catch(() => {});
  }
  return (
    <div>
      {!loading && (
        <div>
          <UserViewProfile user={user} />
          <Row gutter={8}>
            <Col xl={14} xxl={14} xs={24} sm={24} md={24} lg={14}>
              <UserViewStudent user={user} />
              <UserViewTeacher user={user} />
              <UserViewSubscriptions user={user} />
              {user?.auth && (
                <>
                  <ProfileRoles userRoles={user?.auth?.roles ?? []} />
                </>
              )}
            </Col>
            <Col xl={10} xxl={10} xs={24} sm={24} md={24} lg={10}>
              <UserViewLogs user={user} />
            </Col>
          </Row>
        </div>
      )}
      {loading &&
        range(0, 13).map((p) => (
          <Skeleton active={toNumber(p) % 4 === 0} key={`${p}_index`} />
        ))}
    </div>
  );
}
