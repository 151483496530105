import { Card } from "antd";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { DashboardForecastSubjects } from "./compare-forecast-subject";
import { colorPrimary } from "../../utils/theme/color";

export function DashboardForecast() {
  const kpi = useSelector((state: RootState) => state.user.kpi);
  const [levels, setLevels] = React.useState<any[]>([]);
  const [subjects, setSubjects] = React.useState<any[]>([]);

  React.useEffect(() => {
    setLevels(kpi.filter((p) => p.category === "level-forecast") ?? []);
    setSubjects(kpi.filter((p) => p.category === "subject-forecast") ?? []);
  }, [kpi]);

  return (
    <div>
      <Card
        className="card"
        style={{
          marginTop: 0,
          marginBottom: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {levels.length > 0 && (
          <div id="chart">
            <ReactApexChart
              options={{
                chart: {
                  type: "donut",
                },
                labels: levels.map((p) => p.label),
                legend: {
                  position: "bottom",
                },
                /*responsive: [{
                        breakpoint: 480,
                        options: {
                          chart: {
                            width: 200
                          },
                          legend: {
                            position: 'top'
                          }
                        }
                      }]*/
              }}
              series={levels.map((p) => p.value)}
              type="donut"
              width={380}
            />
          </div>
        )}
      </Card>
      <Card
        key="chart-2"
        className="card"
        style={{
          marginTop: 0,
          marginBottom: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {subjects.length > 0 && (
          <div id="chart-subjects">
            <ReactApexChart
              options={{
                chart: {
                  type: "donut",
                },
                labels: subjects.map((p) => p.label),
                legend: {
                  position: "bottom",
                },
                colors: [colorPrimary, "gold"],
                /*responsive: [{
                        breakpoint: 480,
                        options: {
                          chart: {
                            width: 200
                          },
                          legend: {
                            position: 'top'
                          }
                        }
                      }]*/
              }}
              series={subjects.map((p) => p.value)}
              type="donut"
              width={380}
            />
          </div>
        )}
      </Card>
    </div>
  );
}
