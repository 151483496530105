import { Button, Card, Col, Image, Popover, Row, Typography } from "antd";
import React from "react";
import PropTypes from "prop-types";
import { MoreVert } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";

export function SubjectCard({
  image = null,
  centeredTitle = false,
  height = 157,
  isVideo = false,
  content,
  title,
  width = "300px",
  maxLength = 3,
  preview = false,
  level = 4,
  extra = [],
  link = "",
}) {
  const [visible, setVisible] = React.useState(false);
  function handleClose() {
    setVisible(false);
  }
  function handleVisible() {
    setVisible(!visible);
  }
  const PopoverContent = () => {
    return (
      <div>
        <Row style={{ flexDirection: "column" }}>
          {extra.map((p, index) => {
            if (!isEmpty(p.component)) {
              return p.component;
            } else {
              return (
                <Col flex={1}>
                  <Button
                    onClick={p.onCLick}
                    type="text"
                    style={{ width: "100%", textAlign: "left" }}
                  >
                    {p?.title ?? `menu${index}`}
                  </Button>
                </Col>
              );
            }
          })}
        </Row>
      </div>
    );
  };
  return (
    <Card
      className="yc-large yc-card"
      bodyStyle={{ padding: 0 }}
      style={{ maxWidth: width, height: "auto" }}
    >
      <div style={{ height: "100%" }}>
        <Link to={isEmpty(link) ? "#" : link}>
          {!isVideo && (
            <div className="yc-card-image-container" style={{ height: height }}>
              <Image
                preview={preview}
                src={image}
                className="yc-card-image"
                style={{ maxHeight: height }}
              />
            </div>
          )}
          <div style={{ padding: "0 10px", height: "auto" }}>
            <Typography.Title
              level={level}
              style={{
                margin: "5px 0",
                textAlign: centeredTitle ? "center" : "initial",
              }}
            >
              {title}
            </Typography.Title>
            <Typography.Paragraph ellipsis={{ rows: maxLength }}>
              {content}
            </Typography.Paragraph>
          </div>
        </Link>
        {extra.length > 0 && (
          <Row justify="end" style={{ padding: "0" }}>
            <Col>
              <Popover
                onVisibleChange={(e) => {
                  !e && setVisible(e);
                }}
                zIndex={2}
                placement="topLeft"
                arrowPointAtCenter
                title="Menu"
                visible={visible}
                content={<PopoverContent />}
              >
                <Button type="link" onClick={handleVisible}>
                  <MoreVert />
                </Button>
              </Popover>
            </Col>
          </Row>
        )}
      </div>
    </Card>
  );
}

SubjectCard.propTypes = {
  image: PropTypes.string | PropTypes.any,
  isVideo: PropTypes.bool,
  content: PropTypes.string,
  title: PropTypes.string,
  maxLength: PropTypes.number,
  width: PropTypes.string,
  level: PropTypes.number,
  height: PropTypes.number,
  centeredTitle: PropTypes.bool,
  preview: PropTypes.bool,
  extra: PropTypes.array,
};
