import React from "react";
import { AuthLayout } from "./layouts/auth";
import { AdminLayout } from "./layouts/admin";
import "moment/locale/fr";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ConfigProvider } from "antd";
import { lightTheme } from "./utils/theme/light";
import { NotFoundPage } from "./layouts/404";

function App() {
  return (
    <Provider store={store}>
      <ConfigProvider theme={lightTheme}>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<AuthLayout />} />
            <Route path="admin/*" element={<AdminLayout />} />
            {/*<Route path="/" element={<Navigate to="/home/login" replace />} />*/}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
