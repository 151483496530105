import store from "../../redux/store";
import { setChartData, setKPIData } from "../../redux/userReducer";
import userService from "../../services/userService";

export class DataHelper {
  static async getDashboardData() {
    let result: any = [];
    await userService
      .dashboard()
      .then((response) => {
        result = response.data;
        store.dispatch(setKPIData(result?.kpis ?? []));
        store.dispatch(setChartData(result?.charts ?? []));
      })
      .catch(() => {});
    return result;
  }
}
