import { BookFilled, TeamOutlined } from "@ant-design/icons";
import { routeInterface } from "../routes";
import { OrganizationScreen } from "../screens/organization";
import { LessonStudioScreen } from "../screens/lessonStudio";
import { LevelView } from "../screens/level";
import { SubjectScreen } from "../screens/subject";
import { SchoolScreen } from "../screens/school";
import { UniversityScreen } from "../screens/university";
import { StructureView } from "../screens/struct";
import { SubscriptionScreen } from "../screens/subscription/subscription";

export const structureRoutes: routeInterface[] = [
  {
    component: SubjectScreen,
    icon: BookFilled,
    layout: "/admin",
    menu: false,
    label: "Présentation de cours",
    name: "courseScreen",
    path: "/subjects/:id/view",
    font: null,
    roles: ["ADMIN_PRIMARY_ROLES", "EDITION_ROLES"],
  },
  {
    component: LevelView,
    icon: BookFilled,
    layout: "/admin",
    menu: false,
    label: "Classe",
    name: "levelScreen",
    path: "/levels/:id/view",
    font: null,
    roles: ["ADMIN_PRIMARY_ROLES", "TEACHING_ROLES"],
  },
  {
    component: LessonStudioScreen,
    icon: null,
    layout: "/admin",
    menu: false,
    label: "Créer la leçon",
    name: "courseLessonScreen",
    path: "/lessons/:id/create",
    font: null,
    roles: ["ADMIN_PRIMARY_ROLES", "EDITION_ROLES"],
  },
  {
    font: "fa-light fa-folder-tree",
    label: "Organisation",
    name: "structs",
    path: "#",
    layout: "/admin",
    menu: true,
    active: true,
    category: "class",
    icon: "hugeicons:structure-02",
    roles: ["ADMIN_PRIMARY_ROLES", "TEACHING_ROLES", "ADMIN_ROLES"],
    items: [
      {
        component: OrganizationScreen,
        icon: "cbi:echo-show-10",
        layout: "/admin",
        menu: true,
        label: "Présentation",
        name: "structureSceen",
        path: "/structure",
        font: "fa-duotone fa-chart-network",
        parent: "structure",
        roles: ["ADMIN_PRIMARY_ROLES", "TEACHING_ROLES"],
      },
      {
        label: "Toutes les classes",
        layout: "/admin",
        menu: true,
        name: "structSchool",
        path: "/struct/school",
        active: true,
        component: SchoolScreen,
        font: "fa-thin fa-school",
        parent: "structure",
        icon: "mdi:google-classroom",
        roles: ["ADMIN_PRIMARY_ROLES", "TEACHING_ROLES", "ADMIN_ROLES"],
      },

      {
        label: "Toutes les promotions",
        layout: "/admin",
        menu: true,
        name: "structUniv",
        path: "/struct/university",
        active: true,
        component: UniversityScreen,
        font: "fa-thin fa-building-columns",
        parent: "structure",
        icon: "mdi:university-outline",
        roles: ["ADMIN_PRIMARY_ROLES", "TEACHING_ROLES", "ADMIN_ROLES"],
      },
    ],
  },
  {
    label: "Configuration",
    layout: "/admin",
    menu: false,
    name: "structConfig",
    path: "/struct/:id",
    active: true,
    component: StructureView,
    font: "fa-solid",
    parent: "structure",
    roles: ["ADMIN_PRIMARY_ROLES", "TEACHING_ROLES"],
  },

  {
    component: SubscriptionScreen,
    icon: "streamline:subscription-cashflow-solid",
    layout: "/admin",
    menu: true,
    label: "Abonnements",
    name: "subscriptionSceen",
    path: "/subscriptions",
    font: "fa-solid fa-credit-card",
    parent: "payment",
    category: "other",
    roles: ["ADMIN_PRIMARY_ROLES", "FINANCE_ROLES", "ADMIN_ROLES"],
  },
];
