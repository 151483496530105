import { Result } from "antd";
import React from "react";

export function NotFoundPage() {
  return (
    <Result
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
      title="Page non trouvée"
      status="403"
      subTitle="Veuillez contacter votre administrateur"
    />
  );
}
