import { UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Layout, Row, Table, Tag } from "antd";
import { isEmpty, upperCase, upperFirst } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { CreateAgent } from "./agent/create";
import { BreadcrumbComponent } from "../components/breadcrumb";
import { commonRoles } from "../utils/common";
import { AgentFilter } from "./agent/filter";
import { AgentHelper } from "../utils/helpers/agentHelper";
import { useNavigate } from "react-router-dom";
import { getUid } from "../utils/text";

export function RegisterScreen() {
  const [items, setItems] = React.useState<any[]>([]);
  const agents = useSelector((state: RootState) => state.agent.all);
  const filters = useSelector((state: RootState) => state.agent.filters);
  const [loading, setLoading] = React.useState(false);
  const navigation = useNavigate();

  const onInit = React.useCallback(async () => {
    setLoading(true);
    await init();
    setLoading(false);
  }, []);

  React.useEffect(() => {
    onInit();
  }, [onInit]);

  React.useEffect(() => {
    const elements: any[] = [];
    agents.forEach((p) => {
      elements.push({
        uid: p.uid,
        name: `${upperFirst(p.user?.firstName)} ${upperCase(p.user?.lastName)}`,
        photo: p.user?.photo,
        email: p.user?.email,
        roles: p.user?.auth?.roles ?? [],
        phone: p.user?.phone,
        userId: p.user?.uid,
      });
    });
    setItems(elements);
  }, [agents]);

  async function init() {
    await AgentHelper.getAgents({ publish: true });
  }

  const onFilter = React.useCallback(async () => {
    let query = ``;
    const orientationField = filters.find((f) => f.type === "orientation");
    const orderField = filters.find((f) => f.type === "order");
    filters.forEach((p) => {
      const d: any[] = p.data ?? [];
      const v = p.value ?? "";
      if (
        !isEmpty(v) &&
        ["orientation", "order"].findIndex((x) => x === p.type) === -1 &&
        !isEmpty(p.key)
      ) {
        query += `${p.key}=${v}&`;
      } else if (
        d.length > 0 &&
        ["orientation", "order"].findIndex((x) => x === p.type) === -1 &&
        !isEmpty(p.key)
      ) {
        d.forEach((y) => {
          query += `${p.key}=${y}&`;
        });
      }
    });
    if (!isEmpty(orderField)) {
      const k = orderField?.value;
      const v = orientationField?.value ?? "desc";
      query += `${k}=${v}`;
    }
    setLoading(true);
    await AgentHelper.getAgents({ publish: true, query });
    setLoading(false);
  }, [filters]);

  React.useEffect(() => {
    onFilter();
  }, [onFilter]);

  function onClick(item: any) {
    const uid = getUid(item.userId);
    navigation(`/admin/users/${uid}`);
  }

  return (
    <Layout>
      <BreadcrumbComponent title="Les agents">
        <Row gutter={6}>
          <Col>
            <AgentFilter />
          </Col>
          <Col>
            <CreateAgent init={init} />
          </Col>
        </Row>
      </BreadcrumbComponent>
      <Table
        loading={loading}
        dataSource={items}
        className="table__1"
        onRow={(data) => ({
          onClick: () => onClick(data),
        })}
        columns={[
          {
            title: "",
            dataIndex: "photo",
            width: 100,
            align: "center",
            render(value, record, index) {
              return (
                <Avatar src={value}>
                  {isEmpty(value) && <UserOutlined rev="" />}
                </Avatar>
              );
            },
          },
          { title: "Nom complet", dataIndex: "name" },
          { title: "Email", dataIndex: "email" },
          { title: "Téléphone", dataIndex: "phone" },
          {
            title: "Rôles",
            dataIndex: "roles",
            render(value) {
              const list = commonRoles.filter(
                (r) => value?.findIndex((v: any) => v === r.value) !== -1
              );
              return (
                <div>
                  {list.map((p) => (
                    <Tag title={p.title}>{p.label}</Tag>
                  ))}
                </div>
              );
            },
          },
          { title: "", dataIndex: "action" },
        ]}
      />
    </Layout>
  );
}
