import { Button, Card, Col, Layout, notification, Row, Typography } from "antd";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { DlInput, DlPassword } from "../components/input";
import userService from "../services/userService";
import userHelper from "../utils/helpers/userHelper";
import { useLocation, useNavigate } from "react-router-dom";

export function LoginScreen() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loc = useLocation();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required("Champ requis")
        .email("Veuillez écrire votre email correctement"),
    }),
    onSubmit: onLogin,
  });

  async function authUser(code: string, password: string) {
    let data = null;
    await userService
      .login({ username: code, password: password })
      .then((response) => {
        data = response.data;
      })
      .catch(() => {
        data = null;
      });
    return data;
  }

  const onInit = React.useCallback(() => {
    const message = loc.state || {};
    console.log("loc", loc);
    if (!isEmpty(message)) {
      notification.info({
        message: "Veuillez vous connecter",
        description: message,
        duration: 20,
      });
    }
  }, [loc]);
  React.useEffect(() => {
    onInit();
  }, [loc]);

  async function onLogin() {
    const data = formik.values;
    setLoading(true);
    await userService
      .login(data)
      .then(async (response) => {
        const login = response.data;
        userHelper.setToken(login.token);
        delete login.token;
        console.log('LOGIN',login);
        userHelper.setData(login, dispatch);
        window.location.href = "/admin";
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        console.log("error", reason?.response ?? reason);
        notification.warning({
          message: "Echéc de connexion",
          description: error,
        });
        setLoading(false);
      });
  }
  function handleForgotPassword() {
    navigate(`/forgot`);
  }
  return (
    <Layout
      className="yc-bg-home"
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{ minWidth: 500, padding: 0, background: "#fff" }}
        className="yc-card"
      >
        <div
          style={{ width: "100%", textAlign: "center", padding: "10px 0 5px" }}
        >
          <img
            src={require("../assets/images/logo.png")}
            style={{ textAlign: "center", width: 100 }}
          />
        </div>
        <p style={{ textAlign: "center" }}>
          {`Veuillez vous connecter à votre compte ci-dessous.`}
        </p>
        <div style={{ padding: "5px 15px 5px" }}>
          <div style={{ paddingTop: 5 }}>
            <DlInput
              placeholder="your@domain.com"
              type="email"
              formik={formik}
              name="email"
              label="E-mail"
            />
          </div>
          <div style={{ paddingTop: 10 }}>
            <DlPassword
              label="Mot de passe"
              formik={formik}
              name="password"
              placeholder="Mot de passe ici"
            />
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button type="link" onClick={handleForgotPassword}>
            {`Mot de passe oublié`}
          </Button>
        </div>
        <Row
          style={{
            background: "#f1f1f1",
            padding: "15px 10px",
            borderBottomLeftRadius: 10,
            WebkitBorderBottomRightRadius: 10,
          }}
          justify="end"
        >
          <Row gutter={4} align="middle">
            <Col>
              <Button
                disabled={loading}
                loading={loading}
                onClick={() => formik.handleSubmit()}
                type="primary"
              >
                {`Se connecter`}
              </Button>
            </Col>
          </Row>
        </Row>
      </div>
    </Layout>
  );
}
