import { DashboardScreen } from "./screens/dashboard";
import { EstablishmentScreen } from "./screens/ets";
import { TeacherScreen } from "./screens/teacher";
import { LoginScreen } from "./screens/login";
import { RegisterScreen } from "./screens/register";
import { TeacherView } from "./screens/teacher/view";
import { PaymentScreen } from "./screens/subscription/payments";
import { forecatRoutes } from "./routes/forecats";
import { structureRoutes } from "./routes/structurre";
import { SubjectAllScreen } from "./screens/subject/all";
import { StudentAllScreen } from "./screens/students";
import { ForgotPasswordScreen } from "./screens/forgot";
import { ResetPasswordScreen } from "./screens/reset";
import { ProfileScreen } from "./screens/profile";
import { UserViewScreen } from "./screens/user/view";

export declare type userType = "student" | "responsible" | "teacher";

export interface routeInterface {
  component?: any;
  icon?: any;
  layout: "/admin" | "/home";
  menu: boolean;
  label: string;
  name: string;
  path: string;
  font?: string | null;
  group?: string;
  active?: boolean;
  parent?: string;
  items?: routeInterface[];
  category?: string;
  roles?: string[];
}

const mainRoutes: routeInterface[] = [
  {
    component: DashboardScreen,
    icon: "material-symbols-light:dashboard-outline",
    layout: "/admin",
    menu: true,
    label: "Tableau de bord",
    name: "dashboard",
    path: "/",
    category: "general",
    roles: [
      "ADMIN_PRIMARY_ROLES",
      "TEACHING_ROLES",
      "USER_MANAGEMENT_ROLES",
      "EDITION_ROLES",
      "FINANCE_ROLES",
      "ADMIN_ROLES",
    ],
  },
  {
    component: TeacherScreen,
    icon: "fluent-emoji-high-contrast:man-teacher",
    layout: "/admin",
    menu: true,
    label: "Enseignants",
    name: "TeacherScreen",
    path: "/teachers",
    font: "fa-duotone fa-chalkboard-user",
    category: "general",
    roles: [
      "ADMIN_PRIMARY_ROLES",
      "TEACHING_ROLES",
      "USER_MANAGEMENT_ROLES",
      "ADMIN_ROLES",
    ],
  },
  {
    component: EstablishmentScreen,
    icon: "mingcute:school-line",
    layout: "/admin",
    menu: true,
    label: "Etablissements",
    name: "etsScreen",
    path: "/establishments",
    font: "fa-solid fa-school",
    category: "general",
    roles: ["ADMIN_PRIMARY_ROLES", "TEACHING_ROLES", "ADMIN_ROLES"],
  },
  {
    component: LoginScreen,
    icon: null,
    layout: "/home",
    menu: true,
    label: "login",
    name: "loginScreen",
    path: "/",
    font: null,
  },
  {
    component: null,
    icon: null,
    layout: "/home",
    menu: true,
    label: "inscription",
    name: "authScreen",
    path: "/register",
    font: null,
  },
  {
    component: ForgotPasswordScreen,
    icon: null,
    layout: "/home",
    menu: false,
    label: "Mot de passe oublié",
    name: "authForgot",
    path: "/forgot",
    font: null,
  },
  {
    component: ResetPasswordScreen,
    icon: null,
    layout: "/home",
    menu: false,
    label: "Réinitialisation de mot de passe",
    name: "authResetPassword",
    path: "/reset",
    font: null,
  },
  {
    component: null,
    icon: null,
    layout: "/admin",
    menu: false,
    label: "Dossiers",
    name: "folderScreen",
    path: "/folder/:uid/view",
    font: null,
  },
  {
    component: null,
    icon: null,
    layout: "/admin",
    menu: false,
    label: "Paramètres",
    name: "settingScreen",
    path: "/setting",
    font: null,
  },
  {
    component: null,
    icon: null,
    layout: "/admin",
    menu: false,
    label: "profil",
    name: "profilScreen",
    path: "/profil",
    font: null,
  },
  {
    label: "Agents",
    layout: "/admin",
    menu: true,
    name: "RegisterScreen",
    path: "/register",
    active: true,
    component: RegisterScreen,
    font: "fa-duotone fa-users",
    category: "other",
    icon: "subway:admin",
    roles: ["ADMIN_PRIMARY_ROLES"],
  },
  {
    label: "teacher",
    layout: "/admin",
    menu: false,
    name: "TeacherView",
    path: "/teacher/:id/view",
    active: true,
    component: TeacherView,
    roles: ["ADMIN_PRIMARY_ROLES", "TEACHING_ROLES", "USER_MANAGEMENT_ROLES"],
  },
  {
    font: "fa-solid fa-money-bills-simple",
    label: "Paiements",
    name: "payment",
    path: "#",
    layout: "/admin",
    menu: true,
    active: true,
    group: "payment",
    category: "other",
    icon: "grommet-icons:money",
    roles: ["ADMIN_PRIMARY_ROLES", "FINANCE_ROLES"],
  },
  {
    component: PaymentScreen,
    icon: "qlementine-icons:money-16",
    layout: "/admin",
    menu: true,
    label: "Tous les paiements",
    name: "paymentScreen",
    path: "/payments",
    font: "fa-solid fa-money-check-dollar-pen",
    parent: "payment",
    category: "other",
    roles: ["ADMIN_PRIMARY_ROLES", "FINANCE_ROLES"],
  },
  {
    component: SubjectAllScreen,
    icon: "fluent-mdl2:publish-course",
    layout: "/admin",
    menu: true,
    label: "Tous les cours",
    name: "allCourseScreen",
    path: "/courses",
    font: "fa-regular fa-book-open",
    category: "class",
    roles: ["ADMIN_PRIMARY_ROLES", "TEACHING_ROLES", "EDITION_ROLES"],
  },
  {
    component: StudentAllScreen,
    icon: "ph:student-fill",
    layout: "/admin",
    menu: true,
    label: "Tous les étudiants",
    name: "allStudentScreen",
    path: "/students",
    font: "fa-solid fa-graduation-cap",
    category: "general",
    roles: ["ADMIN_PRIMARY_ROLES", "USER_MANAGEMENT_ROLES"],
  },
  {
    component: ProfileScreen,
    label: "",
    layout: "/admin",
    menu: false,
    name: "profile",
    path: "/profile",
  },
  {
    component: UserViewScreen,
    label: "",
    layout: "/admin",
    menu: false,
    name: "user-view",
    path: "/users/:id",
    roles: ["ADMIN_PRIMARY_ROLES", "TEACHING_ROLES"],
  },
];

const routes: routeInterface[] = [];

function addRoutes(...list: routeInterface[][]) {
  for (let items of list) {
    items.map((element) => {
      const index = routes.findIndex((p) => p.name === element.name);
      if (index === -1) {
        routes.push(element);
      }
    });
  }
}

addRoutes(mainRoutes, forecatRoutes, structureRoutes);

function getRouteByPath(layout: "/admin" | "/home", path: string) {
  const url = `${layout}/${path}`;
}

function isCurrentUrl(path: string, current: string) {
  return path === current || path === current + "/";
}

export function getRoutesForBreadcrumb() {
  const elements: any[] = [];
  routes.forEach((p) => {
    const item: any = { ...p };
    item.breadcrumb = item.label;
    item.path = `${item.layout}${item.path}`;
    elements.push(item);
  });
  return elements;
}

export default routes;
