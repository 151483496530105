import store from "../../redux/store";
import { setUserGroups } from "../../redux/userReducer";
import groupService from "../../services/groupService";

export class StructureHelper {
  static async getPrimarySectors() {
    let result: any[] = [];
    await groupService
      .getByKey(`type[]=cycle&type[]=faculty`)
      .then((response) => {
        const data = groupService.getData(response);
        result = data;
        store.dispatch(setUserGroups(data));
      })
      .catch(() => {});
    return result;
  }
}
