import {
  Avatar,
  Button,
  Dropdown,
  Layout,
  Modal,
  notification,
  Table,
  Tag,
  Typography,
} from "antd";
import { isEmpty, toUpper, upperCase, upperFirst } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../redux/store";
import {
  setTeacherFilters,
  setTeacherLength,
  setTeachers,
} from "../redux/teacherReducer";
import teacherService from "../services/teacherService";
import { getGroupTitle, getUid } from "../utils/helpers/title";
import { CreateTeacher } from "./teacher/createTeacher";
import { BreadcrumbComponent } from "../components/breadcrumb";
import { Icon } from "@iconify/react";
import { UserOutlined } from "@ant-design/icons";

export function TeacherScreen() {
  const teachers = useSelector((state: RootState) => state.teacher.list);
  const filters = useSelector((state: RootState) => state.teacher.filter);
  const itemLength = useSelector((state: RootState) => state.teacher.length);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  async function init() {
    const query: any[] = [];
    query.push({ item: "order[users.firstName]", value: "asc" });
    query.push({ item: "order[users.lastName]", value: "asc" });
    query.push({ item: "limit", value: 10 });
    query.push({ item: "page", value: 1 });
    dispatch(setTeacherFilters(query));
  }

  function getCurrentPage() {
    const elements = filters.filter((p) => p.item === "page");
    if (elements.length > 0) {
      const page = elements[0].value;
      return page;
    } else {
      return 1;
    }
  }

  function getCurrentLimit() {
    const elements = filters.filter((p) => p.item === "limit");
    if (elements.length > 0) {
      const page = elements[0].value;
      return page;
    } else {
      return 10;
    }
  }

  React.useEffect(() => {
    if (teachers.length === 0) {
      init();
    }
  }, []);

  React.useEffect(() => {
    getTeachers();
  }, [filters]);

  async function getTeachers() {
    let query = ``;
    filters.forEach((p) => {
      query += `${p.item}=${p.value}&`;
    });
    setLoading(true);
    await teacherService
      .getByKey(query)
      .then((response) => {
        const data = teacherService.getData(response);
        const count = teacherService.getTotal(response);
        dispatch(setTeachers(data));
        dispatch(setTeacherLength(count));
      })
      .catch(() => {});
    setLoading(false);
  }

  function handleChangePage(page: number, pageSize: number) {
    const elements = filters.filter(
      (p) => p.item !== "page" && p.item !== "limit"
    );
    elements.push({ item: "page", value: page });
    elements.push({ item: "limit", value: pageSize });
    dispatch(setTeacherFilters(elements));
  }

  const { Text } = Typography;

  async function onDelete(uid: string) {
    await teacherService
      .destroy(uid)
      .then((response) => {
        init();
      })
      .catch((reason) => {
        const error = teacherService.getError(reason);
        notification.error({
          message: "Suppréssion de l'enseignant",
          description: error,
        });
      });
  }

  function handleDelete(uid: string, name: string) {
    Modal.confirm({
      title: "Suppréssion de l'enseignant",
      content: (
        <p>
          Voulez-vous supprimer <b>{name}</b> de façon définitive?
        </p>
      ),
      okText: "Supprimer",
      cancelText: "Annuler",
      onOk: async () => {
        await onDelete(uid);
      },
    });
  }

  function handleOpen(data: any) {
    navigate(`/admin/users/${getUid(data?.users?.uid)}`);
  }

  return (
    <Layout className="box">
      <BreadcrumbComponent title="Tous les enseignants">
        <CreateTeacher onRelaod={getTeachers} />
      </BreadcrumbComponent>
      <Table
        dataSource={teachers}
        loading={loading}
        pagination={{
          current: getCurrentPage(),
          showSizeChanger: true,
          disabled: loading,
          defaultCurrent: 1,
          pageSize: getCurrentLimit(),
          total: itemLength,
          onChange: handleChangePage,
        }}
        className="table__1 table"
        columns={[
          {
            dataIndex: "users",
            render(value, record, index) {
              return (
                <Avatar size="large" src={value?.photo}>
                  {isEmpty(value?.photo) && <UserOutlined rev="" />}
                </Avatar>
              );
            },
            onCell: (data: any) => ({
              onClick: () => {
                handleOpen(data);
              },
            }),
          },
          {
            title: "Nom complet",
            dataIndex: "users",
            render(value) {
              return `${upperFirst(value?.firstName)} ${toUpper(
                value?.lastName
              )}`;
            },
            onCell: (data: any) => ({
              onClick: () => {
                handleOpen(data);
              },
            }),
          },
          {
            title: "Téléphone",
            dataIndex: "users",
            render(value, record, index) {
              return value?.phone;
            },
            onCell: (data: any) => ({
              onClick: () => {
                handleOpen(data);
              },
            }),
          },
          {
            title: "Classes de prestation",
            dataIndex: "commitments",
            render(value, record, index) {
              return (
                <div>
                  {value?.length > 0 ? (
                    <div>
                      {value?.map((p: any, index: number) => {
                        if (index < 5) {
                          return (
                            <Tag title={getGroupTitle(p?.level?.groups)}>
                              {p?.level?.title}
                            </Tag>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  ) : (
                    <Text strong>{`Aucune classe n'est définie`}</Text>
                  )}
                </div>
              );
            },
            onCell: (data: any) => ({
              onClick: () => {
                handleOpen(data);
              },
            }),
          },
          {
            render(value, record, index) {
              return (
                <div>
                  <Dropdown
                    trigger={["click", "contextMenu"]}
                    menu={{
                      title: "",
                      items: [
                        {
                          label: "Ouvrir",
                          key: "open",
                          onClick: () => handleOpen(value),
                          icon: (
                            <Icon
                              icon="lets-icons:view-alt"
                              width="16"
                              height="16"
                            />
                          ),
                        },
                        {
                          label: "Modifier",
                          key: "edit",
                          icon: (
                            <Icon icon="tabler:edit" width="16" height="16" />
                          ),
                        },
                        {
                          label: "Supprimer",
                          key: "delete",
                          onClick: () =>
                            handleDelete(
                              value.uid,
                              `${upperFirst(
                                value?.users?.firstName
                              )} ${upperCase(value?.users?.lastName)}`
                            ),
                          icon: (
                            <Icon
                              color={"brown"}
                              icon="fluent:delete-20-regular"
                              width="16"
                              height="16"
                            />
                          ),
                        },
                      ],
                    }}
                  >
                    <Button
                      type="text"
                      icon={
                        <Icon
                          icon="qlementine-icons:menu-dots-16"
                          width="24"
                          height="24"
                        />
                      }
                      shape="circle"
                    ></Button>
                  </Dropdown>
                </div>
              );
            },
          },
        ]}
      />
    </Layout>
  );
}
