import Crud from "./crud";

class UserService extends Crud {
  url = "users";
  destroy(id: string | number) {
    return this.unsecureAxios.delete(`${this.url}/${id}`);
  }
  get(id: string | number) {
    return this.unsecureAxios.get(`${this.url}/${id}`);
  }
  getAll() {
    return this.unsecureAxios.get(`${this.url}`);
  }
  getByKey(query: string) {
    return this.unsecureAxios.get(`${this.url}?${query}`);
  }
  store(data: any) {
    return this.unsecureAxios.post(`${this.url}`, data);
  }
  update(id: string | number, data: any) {
    return this.unsecureAxios.put(`${this.url}/${id}`, data);
  }
  updateAuth(id: string | number, data: any) {
    return this.axios.put(`auths/${id}`, data);
  }
  updatePhoto(data: any) {
    return this.unsecureAxios.post(`${this.url}/update/photo`, data);
  }
  login(data: any) {
    return this.unsecureAxios.post(`login/admin`, data);
  }
  connect(data: any) {
    return this.unsecureAxios.post(`usersms`, data);
  }
  forgotPassword(data: any) {
    return this.unsecureAxios.post(`forgot_password`, data);
  }
  resetPassword(data: any) {
    return this.unsecureAxios.post(`reset_password`, data);
  }
  dashboard(query?: string) {
    return this.unsecureAxios.get(`dashboard?${query ?? ""}`);
  }
}
const userService = new UserService();
export default userService;
