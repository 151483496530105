import React from "react";
import store, { RootState } from "../../../redux/store";
import { Button, Checkbox, Modal, notification } from "antd";
import { Icon } from "@iconify/react";
import { commonRoles } from "../../../utils/common";
import { useFormik } from "formik";
import userService from "../../../services/userService";
import { setCurrentUser, setUserProfile } from "../../../redux/userReducer";
import { useSelector } from "react-redux";

export const UserUpdateRole: React.FC<{ user: any }> = ({ user }) => {
  const [loading, setLoading] = React.useState(false);
  const profile = useSelector((state: RootState) => state.user.profile);
  const [open, setOpen] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      roles: [] as string[],
    },
    onSubmit,
  });

  const onUser = React.useCallback(() => {
    const roles = user.auth?.roles ?? [];
    formik.setFieldValue("roles", roles);
  }, [user, open]);
  React.useEffect(() => {
    onUser();
  }, [onUser]);

  async function onSubmit() {
    const { roles } = formik.values;
    setLoading(true);
    await userService
      .updateAuth(user.auth?.uid, { roles })
      .then(async () => {
        await loadData(user.uid);
        notification.info({ message: "Rôles modifié avec succès" });
        setOpen(false);
        formik.resetForm();
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          message: "Erreur lors de la modification",
          description: error,
        });
      });
    setLoading(false);
  }

  async function loadData(id: string) {
    await userService
      .get(id)
      .then((response) => {
        store.dispatch(setCurrentUser(response.data));
        if (profile.uid === user.uid) {
          store.dispatch(setUserProfile(response.data));
        }
      })
      .catch(() => {});
  }

  function handleSubmit() {
    formik.handleSubmit();
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Button
        icon={<Icon icon="oui:app-users-roles" width="32" height="32" />}
        onClick={handleOpen}
        disabled={
          profile?.auth?.roles?.findIndex(
            (p: any) => p === "ADMIN_PRIMARY_ROLES"
          ) === -1
        }
      >
        {`Changer de rôle`}
      </Button>
      <Modal
        confirmLoading={loading}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText="Modifier"
        cancelText="Annuler"
        open={open}
        onClose={handleClose}
        title="Rôles"
        centered
      >
        <div>
          <div>
            <Checkbox.Group
              options={commonRoles.map((p) => ({
                label: p.label,
                value: p.value,
              }))}
              style={{ display: "flex", flexDirection: "column" }}
              value={formik.values.roles}
              onChange={(v) => formik.setFieldValue("roles", v)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
