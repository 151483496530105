import { Avatar, Button, Card, Col, Row, Spin, Tag, Typography } from "antd";
import React from "react";
import { isEmpty, upperCase, upperFirst } from "lodash";
import { Icon } from "@iconify/react";
import { ItemValue } from "../../components/item-value";
import { ProfileUpdatePhoto } from "./profile-update-photo";
import { colorPrimary } from "../../utils/theme/color";
import { commonGender, commonRoles } from "../../utils/common";
import { UserUpdateRole } from "./operation/changeRole";
import { UserUpdateProfile } from "./operation/updateProfile";

const { Text, Title } = Typography;

export const UserViewProfile: React.FC<{ user: any }> = ({ user }) => {
  const [uploading, setUploading] = React.useState(false);
  const [grade, setGrade] = React.useState("");
  const [useRoles, setUserRoles] = React.useState<any[]>([]);

  const onUser = React.useCallback(() => {
    let label = "";
    if (!isEmpty(user.teacher)) label += "Enseignant, ";
    if (!isEmpty(user.admin)) label += "Administrateur, ";
    if (!isEmpty(user.responsible)) label += "Parent|Titeur, ";
    if (!isEmpty(user.student)) label += "Etudiants ";
    setGrade(label);
    const authRoles = user.auth?.roles ?? [];
    const list = commonRoles.filter(
      (r) => authRoles.findIndex((v: any) => v === r.value) !== -1
    );
    setUserRoles(list ?? []);
  }, [user]);

  React.useEffect(() => {
    onUser();
  }, [onUser]);
  return (
    <section>
      <Card
        bordered={false}
        styles={{
          cover: { height: 200 },
        }}
        style={{ position: "relative" }}
        cover={
          <div style={{ position: "relative", height: "100%" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={require("../../assets/images/event.jpg")}
            />
            <div
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 5,
                position: "absolute",
                padding: 20,
                bottom: -60,
                zIndex: 3,
              }}
            >
              <div style={{ position: "relative" }}>
                <Spin spinning={uploading}>
                  <Avatar
                    style={{
                      background: colorPrimary,
                      borderWidth: 6,
                      borderColor: "#FFF",
                    }}
                    src={user?.photo}
                    size={120}
                  >
                    {isEmpty(user?.photo) && (
                      <Icon icon="mdi:user" width="48" height="48" />
                    )}
                  </Avatar>
                </Spin>
                <div style={{ position: "absolute", bottom: 0, right: 0 }}>
                  <ProfileUpdatePhoto
                    userId={user.uid}
                    onUploading={setUploading}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      >
        <div style={{ padding: "30px 30px 0 10px" }}>
          <Row gutter={12}>
            <Col xl={16} xxl={16} lg={16} xs={24} md={12} sm={24}>
              <Title
                level={1}
                style={{ fontSize: 28, margin: 0 }}
              >{`${upperFirst(user.firstName)} ${upperCase(
                user.lastName
              )}`}</Title>
              <Text style={{ fontFamily: "Poppins-Light" }}>{grade}</Text>
              <div>
                {useRoles.map((p) => (
                  <Tag color={p.color} title={p.title}>
                    {p.label}
                  </Tag>
                ))}
              </div>
              <div style={{ padding: "20px 0 0" }}>
                <Row gutter={4} align={"middle"}>
                  <Col>
                    <UserUpdateProfile user={user} />
                  </Col>
                  {!isEmpty(user?.admin) && isEmpty(user?.teacher) && (
                    <Col>
                      <UserUpdateRole user={user} />
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
            <Col xl={8} xxl={8} lg={8} xs={24} md={12} sm={24}>
              <div style={{ padding: "0 20px" }}>
                <ItemValue
                  value={user.phone}
                  prefix={
                    <Icon icon="line-md:phone-filled" width="24" height="24" />
                  }
                />
                {!isEmpty(user.email) && (
                  <ItemValue
                    value={user.email}
                    prefix={
                      <Icon
                        icon="streamline:send-email-solid"
                        width="24"
                        height="24"
                      />
                    }
                  />
                )}
                <ItemValue
                  inline
                  label=""
                  value={`${commonGender(user.gender) ?? "Non spécifié"}`}
                  prefix={
                    <Icon
                      icon="icons8:gender-neutral-user"
                      width="32"
                      height="32"
                    />
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </section>
  );
};
