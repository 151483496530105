import { isEmpty } from "lodash";
import { setAllAgents } from "../../redux/agentReducer";
import store from "../../redux/store";
import adminService from "../../services/adminService";

export class AgentHelper {
  static async getAgents(
    props: { publish?: boolean; query?: string } = { publish: true }
  ) {
    let query = props.query ?? "";
    if (isEmpty(query)) {
      query = `limit=1000`;
    }
    let data: any[] = [];
    await adminService
      .getByKey(`${query}`)
      .then((response) => {
        data = adminService.getData(response);
        if (props.publish) {
          store.dispatch(setAllAgents(data));
        }
      })
      .catch((reason) => {});
    return data;
  }
}
