import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Col, Layout, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useNavigate } from "react-router-dom";
import studentService from "../services/studentService";
import { setAllStudents } from "../redux/studentReducer";
import userHelper from "../utils/helpers/userHelper";
import { upperCase, upperFirst } from "lodash";
import { BreadcrumbComponent } from "../components/breadcrumb";
import { getUid } from "../utils/text";

export function StudentAllScreen() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const items = useSelector((state: RootState) => state.student.all);
  const columns: ColumnsType<any> = [
    {
      dataIndex: "users",
      title: "Nom de l'étudiant",
      align: "left",
      render(value, record, index) {
        return `${upperFirst(value.firstName)} ${upperCase(value.lastName)}`;
      },
    },
    {
      dataIndex: "level",
      title: "Promotion",
      render(value, record, index) {
        return value?.title;
      },
      align: "left",
    },
    { title: "Etablissement", dataIndex: "establishment", align: "center" },
  ];

  async function init() {
    if (items.length === 0) {
      setLoading(true);
    }
    await studentService
      .getByKey(`order[users.firstName]=asc&order[users.lastName]=asc`)
      .then((response) => {
        const data = studentService.getData(response);
        dispatch(setAllStudents(data));
      })
      .catch(() => {});
    setLoading(false);
  }

  React.useEffect(() => {
    init();
  }, []);

  function onClick(item: any) {
    const uid = getUid(item.users?.uid);
    navigation(`/admin/users/${uid}`);
  }

  return (
    <React.Fragment>
      <Layout>
        <BreadcrumbComponent title="Liste de tous les étudiants">
          <Row gutter={6}>
            <Col></Col>
          </Row>
        </BreadcrumbComponent>
        <div className="box yc-card">
          <Table
            dataSource={items}
            columns={columns}
            loading={loading}
            className="yc-table"
            onRow={(data: any) => {
              return {
                onClick: () => onClick(data),
              };
            }}
          />
        </div>
      </Layout>
    </React.Fragment>
  );
}
