import React from "react";
import { useSelector } from "react-redux";
import store, { RootState } from "../../redux/store";
import { isEmpty } from "lodash";
import { Card, List } from "antd";
import studentService from "../../services/studentService";
import { setCurrentStudent } from "../../redux/studentReducer";
import { ItemValue } from "../../components/item-value";
import managementService from "../../services/managementService";
import { setManagementList } from "../../redux/managementReducer";
import { getSectorType } from "../../utils/common";

export const UserViewStudent: React.FC<{ user: any }> = ({ user }) => {
  const student = useSelector((state: RootState) => state.student.current);
  const managements = useSelector((state: RootState) => state.management.list);
  const readings = useSelector((state: RootState) => state.student.readings);
  const onUser = React.useCallback(async () => {
    if (!isEmpty(user.student)) {
      await studentService
        .get(user.student.uid)
        .then((response) => {
          store.dispatch(setCurrentStudent(response.data));
        })
        .catch(() => {});
      await managementService
        .getByKey(`student=${user.student.uid}`)
        .then((response) => {
          const list = managementService.getData(response);
          store.dispatch(setManagementList(list));
        })
        .catch(() => {});
    }
  }, [user]);
  React.useEffect(() => {
    onUser();
  }, [onUser]);

  const renderItem = (item: any) => {
    return <List.Item></List.Item>;
  };

  const renderSubject = (item: any) => {
    return <List.Item></List.Item>;
  };
  return (
    <>
      {!isEmpty(student) && (
        <Card
          title="Education"
          className="card__1"
          style={{ margin: "10px 0" }}
          styles={{ header: { background: "#F9FAFD" } }}
        >
          <ItemValue label="Ecole" value={student.establishment} />
          <ItemValue label="Classe" value={student.level?.title} />
          <ItemValue
            label={getSectorType(student.level?.groups?.type)}
            value={student.level?.groups?.name}
          />
        </Card>
      )}

      {managements.length > 0 && !isEmpty(student) && (
        <Card
          title="Enseignants en charge"
          className="card__1"
          style={{ margin: "10px 0" }}
          styles={{ header: { background: "#F9FAFD" } }}
        >
          <List
            dataSource={managements}
            grid={{ xxl: 5, xl: 5, lg: 4, xs: 1, sm: 2, md: 2, gutter: 6 }}
            renderItem={renderItem}
          />
        </Card>
      )}

      {readings.length > 0 && (
        <Card
          title="Lectures des leçons"
          className="card__1"
          style={{ margin: "10px 0" }}
          styles={{ header: { background: "#F9FAFD" } }}
        >
          <List
            dataSource={readings}
            grid={{ xxl: 5, xl: 5, lg: 4, xs: 1, sm: 2, md: 2, gutter: 6 }}
            renderItem={renderSubject}
          />
        </Card>
      )}
    </>
  );
};
