import { isEmpty, random } from "lodash";

const appName = "yeccolapp";
const refKey = "yecc-ref";

const setAppTitle = (value: string) => {
  document.title = `${appName}${!isEmpty(value) ? " | " : ""}${value}`;
};

const getUid = (value: string): string => {
  if (typeof value !== "string") {
    return "";
  }
  if (isEmpty(value)) {
    return "";
  }
  let response = "";
  const items = value.split("/");
  if (items.length > 0) {
    response = items[items.length - 1];
  }
  return response;
};

export function cleanHTML(str: string) {
  let response = "";
  if (!isEmpty(str)) {
    str = str.toString();
    response = str.replace(/<[^>]*>/g, "");
  }
  return response;
}
export function getLessonPoster(files: any[]) {
  const index = random(false);
  const images = files.filter((p) => p.typeMime.search("image") != -1);
  let response = `https://picsum.photos/800/600?random=${index}`;
  if (images.length > 0) {
    response = images[0].file;
  }
  return response;
}
const yecKey = "yeccolapp_admin_login";
const tokenKey = "yeccolapp_admin_token";

export function getToken(): string | null {
  let response = null;
  const item = localStorage.getItem(tokenKey);
  if (typeof item === "string") {
    response = item;
  }
  return response;
}

export function getGroupTitle(group: any) {
  if (isEmpty(group)) {
    return "";
  }
  const type = group?.type;
  switch (type) {
    case "cycle":
      return "cycle";
    case "section":
      return "section";
    case "option_class":
      return "option";
    case "faculty":
      return "faculté";
    case "department":
      return "département";
    case "option":
      return "option";
    default:
      return "";
  }
}
export { appName, getUid, refKey, setAppTitle, yecKey, tokenKey };
