import React from "react";
import { Menu, menuClasses, MenuItemStyles, Sidebar } from "react-pro-sidebar";
import { hexToRgba } from "../../utils/common";
import { sidebarThemes } from "../../utils/theme/sidebar-theme";
import { SidebarHeader } from "./sidebar/SidebarHeader";
import { Typography } from "./sidebar/Typography";
import routes from "../../routes";
import { SidebarFooter } from "./sidebar/SidebarFooter";
import { SidebarMenu } from "./sidebar/sidebar-menu";

export const YecProSidebar: React.FC<{
  collapsed: boolean;
  rtl: boolean;
  toggled: boolean;
  onToggled?: (v: boolean) => void;
  theme: "light" | "dark";
  hasImage?: boolean;
  setBroken?: (broken: boolean) => void;
  onClose?: () => void;
}> = ({
  collapsed,
  toggled,
  rtl,
  theme = "light",
  hasImage,
  setBroken,
  onClose,
}) => {
  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: sidebarThemes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: sidebarThemes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              sidebarThemes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1
            )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: sidebarThemes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          sidebarThemes[theme].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1
        ),
        color: sidebarThemes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <Sidebar
      collapsed={collapsed}
      toggled={toggled}
      onBackdropClick={onClose}
      onBreakPoint={setBroken}
      image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
      rtl={rtl}
      breakPoint="md"
      backgroundColor={hexToRgba(
        sidebarThemes[theme].sidebar.backgroundColor,
        hasImage ? 0.9 : 1
      )}
      //backgroundColor="#EDF2F8"
      rootStyles={{
        color: sidebarThemes[theme].sidebar.color,
      }}
      style={{
        position: "fixed",
        zIndex: 3,
        height: "100%",
        padding: "0 0 50px",
        background: "transparent",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <SidebarHeader
          rtl={rtl}
          style={{
            marginBottom: "24px",
            marginTop: "16px",
            paddingTop: 20,
            display: "none",
          }}
        />
        <div style={{ flex: 1, marginBottom: "10px" }}>
          <div style={{ padding: "0 24px", marginBottom: "8px" }}>
            <Typography
              variant="body2"
              fontWeight={600}
              style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: "0.5px" }}
            >
              General
            </Typography>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            {routes
              .filter(
                (p) =>
                  p.menu && p.layout === "/admin" && p.category === "general"
              )
              .map((p, i) => (
                <SidebarMenu menu={p} key={`menu_${i}`} />
              ))}
          </Menu>

          <div
            style={{
              padding: "0 24px",
              marginBottom: "4px",
              marginTop: "16px",
            }}
          >
            <Typography
              variant="body2"
              fontWeight={600}
              style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: "0.5px" }}
            >
              {`Classes et structures`}
            </Typography>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            {routes
              .filter(
                (p) => p.menu && p.layout === "/admin" && p.category === "class"
              )
              .map((p, i) => (
                <SidebarMenu menu={p} key={`menu_${i}`} />
              ))}
          </Menu>
          <div
            style={{
              padding: "0 24px",
              marginBottom: "4px",
              marginTop: "16px",
            }}
          >
            <Typography
              variant="body2"
              fontWeight={600}
              style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: "0.5px" }}
            >
              Autres
            </Typography>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            {routes
              .filter(
                (p) => p.menu && p.layout === "/admin" && p.category === "other"
              )
              .map((p, i) => (
                <SidebarMenu menu={p} key={`menu_${i}`} />
              ))}
          </Menu>
        </div>
        <SidebarFooter collapsed={collapsed} />
      </div>
    </Sidebar>
  );
};
