import { Button, Col, Layout, notification, Row } from "antd";
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { DlInput, DlPassword } from "../components/input";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import userService from "../services/userService";

export function ResetPasswordScreen() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      password: "",
      confirm: "",
      email: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required("Champ requis")
        .email("Veuillez écrire votre email correctement"),
      password: yup.string().required("Le mot de passe est requis"),
      confirm: yup
        .string()
        .oneOf(
          [yup.ref("password"), null],
          "Les mots de passe ne correspondent pas"
        )
        .required("Confirmation du mot de passe requise"),
    }),
    async onSubmit(values) {
      setLoading(true);
      const token = searchParams.get("token");
      const { password, email } = values;
      const data: any = {
        password,
        email,
        code: token,
      };
      await userService
        .resetPassword(data)
        .then(() => {
          navigate(`/`, {
            replace: true,
            state: `Vous avez réussi à vous réinitialiser votre mot de passe. Veuillez vous connecter.`,
          });
        })
        .catch((reason) => {
          const error = userService.getError(reason);
          notification.error({
            message: "Erreur lors de la réinitialisation",
            description: error,
          });
          setLoading(false);
        });
    },
  });
  function handleLogin() {
    navigate(`/`);
  }
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");

  if (!token) {
    return <Navigate to="/notfound" replace />;
  }
  return (
    <Layout
      className="yc-bg-home"
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{ minWidth: 500, padding: 0, background: "#fff" }}
        className="yc-card"
      >
        <div
          style={{ width: "100%", textAlign: "center", padding: "10px 0 5px" }}
        >
          <img
            src={require("../assets/images/logo.png")}
            style={{ textAlign: "center", width: 100 }}
          />
        </div>
        <p style={{ textAlign: "center" }}>
          {`Veuillez réinitialiser votre mot de passe.`}
        </p>
        <div style={{ padding: "5px 15px 5px" }}>
          <div style={{ paddingTop: 5 }}>
            <DlInput
              placeholder="your@domain.com"
              type="email"
              formik={formik}
              name="email"
              label="E-mail"
            />
          </div>
          <div style={{ paddingTop: 10 }}>
            <DlPassword
              label="Mot de passe"
              formik={formik}
              name="password"
              placeholder="Mot de passe ici"
            />
          </div>
          <div style={{ paddingTop: 10 }}>
            <DlPassword
              label="Confirmation de mot de passe"
              formik={formik}
              name="confirm"
              placeholder="Mot de passe de confirmation ici"
            />
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button type="link" onClick={handleLogin}>
            {`Se connecter`}
          </Button>
        </div>
        <Row
          style={{
            background: "#f1f1f1",
            padding: "15px 10px",
            borderBottomLeftRadius: 10,
            WebkitBorderBottomRightRadius: 10,
          }}
          justify="end"
        >
          <Row gutter={4} align="middle">
            <Col>
              <Button
                disabled={loading}
                loading={loading}
                onClick={() => formik.handleSubmit()}
                type="primary"
              >
                {`Valider`}
              </Button>
            </Col>
          </Row>
        </Row>
      </div>
    </Layout>
  );
}
