import {
  Button,
  Layout,
  notification,
  Progress,
  Skeleton,
  Spin,
  Tabs,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useFormik } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import chapiterService from "../services/chapiterService";
import subjectService from "../services/subjectService";
import { ContentLesson } from "./lesson/content";
import { DocLesson } from "./lesson/docs";
import { IdentityLesson } from "./lesson/indentity";
import { EditorState } from "draft-js";
import { VideoLesson } from "./lesson/videos";
import { UploadFile } from "antd/lib/upload/interface";
import { isEmpty } from "lodash";
import lessonService from "../services/lessonService";
import { getUid } from "../utils/helpers/title";
import fileService from "../services/fileService";

export function LessonStudioScreen() {
  const params = useParams();
  const [subject, setSubject] = React.useState<any>({});
  const [chapters, setChapters] = React.useState<any[]>([]);
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [video, setVideo] = React.useState<UploadFile | null>(null);
  const [videoThumb, setVideoThumb] = React.useState<UploadFile | null>(null);
  const [docs, setDocs] = React.useState<UploadFile[]>([]);
  const [progress, setProgress] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  let editoState = EditorState.createEmpty();
  const [content, setContent] = React.useState(editoState);
  const formikIdentity = useFormik({
    initialValues: {
      title: "",
      poster: null,
      subject: null,
      chapiter: null,
    },
    onSubmit: () => {},
  });
  const [loading, setLoading] = React.useState(false);
  const [treating, setTreating] = React.useState(false);

  React.useEffect(() => {
    init();
  }, [params]);

  async function init() {
    setLoading(true);
    chapiterService
      .getByKey(`subject=${params.id}&limit=1000&order[priority]=asc`)
      .then((response) => {
        setChapters(chapiterService.getData(response));
      })
      .catch(() => {});
    await subjectService
      .get(params?.id as string)
      .then((response) => {
        setSubject(response.data);
      })
      .catch(() => {});
    setLoading(false);
  }

  async function handleSave() {
    if (!isCorrect()) {
      return;
    }
    let som = 0;
    som += docs.length;
    som += 1; //vidéo
    som += 1; //lesson
    setTotal(som);
    setTreating(true);
    const data: any = {
      title: formikIdentity.values.title,
      subject: `/api/subjects/${params.id}`,
      state: "in_progress",
      content: "",
    };
    if (!isEmpty(formikIdentity.values.chapiter)) {
      data.chapiter = `/api/chapiters/${formikIdentity.values.chapiter}`;
    }
    setProgress(1);
    await lessonService
      .store(data)
      .then(async (response) => {
        const elem = response.data;
        setProgress(progress + 1);
        await saveVideo(getUid(elem["@id"]));
        if (docs.length > 0) {
          await saveDocs(getUid(elem["@id"]));
        }
        notification.info({
          message: "Création de leçon",
          description: "La leçon a été ajouté avec succès",
        });
        window.history.back();
      })
      .catch((reason) => {
        console.log("LESSON", reason.response ?? reason);
      });
    setTreating(false);
  }

  async function saveDocs(uid: string) {
    await Promise.all(
      docs.map(async (p) => {
        const data = new FormData();
        data.append("file", p?.originFileObj ?? "");
        data.append("lesson", uid);
        setProgress(progress + 1);
        await fileService
          .store(data)
          .then((response) => {
            console.log("REP", response);
          })
          .catch((reason) => {
            console.log("ERROR DOC", reason);
          });
      })
    );
  }
  async function saveVideo(uid: string) {
    const data = new FormData();
    data.append("file", video?.originFileObj ?? "");
    data.append("thumb", videoThumb?.originFileObj ?? "");
    data.append("lesson", uid);
    await fileService
      .store(data)
      .then(() => {})
      .catch((reason) => {
        console.log("ERROR", reason);
      });
  }

  function isCorrect() {
    let response = true;
    if (isEmpty(formikIdentity.values.title)) {
      response = false;
    }
    if (isEmpty(video)) {
      response = false;
    }
    if (isEmpty(videoThumb)) {
      //response = false;
    }
    return response && !treating;
  }

  return (
    <Layout>
      {!loading && (
        <React.Fragment>
          <PageHeader
            title="Ajouter une nouvelle leçon"
            onBack={() => window.history.back()}
            style={{ background: "#f1f1f1" }}
            extra={[
              <Button
                type="primary"
                loading={treating}
                disabled={!isCorrect()}
                onClick={handleSave}
              >
                {`Enregistrer`}
              </Button>,
            ]}
          ></PageHeader>
          <Tabs
            type="card"
            centered
            animated
            tabBarStyle={{ background: "#f1f1f1" }}
          >
            <Tabs.TabPane tab="Information de base" key="0">
              <IdentityLesson
                chapters={chapters}
                subject={subject}
                formik={formikIdentity}
                fileList={fileList}
                setFileList={setFileList}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Vidéos" key="1">
              <VideoLesson
                file={video}
                setFile={setVideo}
                setThumb={setVideoThumb}
                thumb={videoThumb}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Documents" key="2">
              <DocLesson fileList={docs} setFileList={setDocs} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Contenu" key="3">
              <ContentLesson
                description={content}
                setDescription={setContent}
              />
            </Tabs.TabPane>
          </Tabs>
          {treating && (
            <div
              style={{
                background: "#0001",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                height: "100vh",
                width: "100%",
              }}
            >
              <Progress
                percent={
                  progress <= total && total > 0 ? (progress / total) * 100 : 0
                }
                type="circle"
                showInfo={false}
              />
              <div style={{ position: "absolute", alignSelf: "center" }}>
                <Spin />
              </div>
            </div>
          )}
        </React.Fragment>
      )}
      {loading && (
        <React.Fragment>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </React.Fragment>
      )}
    </Layout>
  );
}
