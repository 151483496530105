import { PayloadAction } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  current: any;
  list: any[];
  all: any[];
  filters: any[];
} = {
  current: {},
  list: [],
  all: [],
  filters: [
    { type: "page", value: "1", key: "page" },
    { type: "limit", value: "10", key: "limit" },
  ],
};

export const agentSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {
    setAgent: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setAgents: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setAllAgents: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
    setAgentFilters: (state, action: PayloadAction<any[]>) => {
      state.filters = action.payload;
    },
  },
});
export const { setAgent, setAgents, setAllAgents, setAgentFilters } =
  agentSlice.actions;
export default agentSlice.reducer;
