import React from "react";
import { useSelector } from "react-redux";
import store, { RootState } from "../../redux/store";
import { isEmpty } from "lodash";
import { Card, List } from "antd";
import teacherService from "../../services/teacherService";
import { setCurrentTeacher } from "../../redux/teacherReducer";
import managementService from "../../services/managementService";
import { setManagementList } from "../../redux/managementReducer";
import { ItemValue } from "../../components/item-value";
import { getSectorType } from "../../utils/common";

export const UserViewTeacher: React.FC<{ user: any }> = ({ user }) => {
  const teacher = useSelector((state: RootState) => state.teacher.current);
  const managements = useSelector((state: RootState) => state.management.list);
  const onUser = React.useCallback(async () => {
    if (!isEmpty(user.teacher)) {
      await teacherService
        .get(user.teacher.uid)
        .then((response) => {
          store.dispatch(setCurrentTeacher(response.data));
        })
        .catch(() => {});
      await managementService
        .getByKey(`teacher=${user.teacher.uid}`)
        .then((response) => {
          const list = managementService.getData(response);
          store.dispatch(setManagementList(list));
        })
        .catch(() => {});
    }
  }, [user]);
  React.useEffect(() => {
    onUser();
  }, [onUser]);

  const renderItem = (item: any) => {
    return <List.Item></List.Item>;
  };

  const renderCommitment = (item: any) => {
    return (
      <List.Item>
        <ItemValue label="Classe" value={item.level?.title} />
        <ItemValue
          label={getSectorType(item.level?.groups?.type)}
          value={item.level?.groups?.name}
        />
      </List.Item>
    );
  };
  return (
    <>
      {!isEmpty(teacher) && (
        <Card
          title="Classes suivies"
          style={{ margin: "10px 0" }}
          styles={{ header: { background: "#F9FAFD" } }}
          className="card__2"
        >
          <List
            dataSource={teacher.commitments}
            renderItem={renderCommitment}
          />
        </Card>
      )}
      {managements.length > 0 && !isEmpty(teacher) && (
        <Card
          title="Etudiants en charge"
          className="card__1"
          style={{ margin: "10px 0" }}
          styles={{ header: { background: "#F9FAFD" } }}
        >
          <List
            dataSource={managements}
            grid={{ xxl: 5, xl: 5, lg: 4, xs: 1, sm: 2, md: 2, gutter: 6 }}
            renderItem={renderItem}
          />
        </Card>
      )}
    </>
  );
};
