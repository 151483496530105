import { Alert, Button, Col, Layout, notification, Row } from "antd";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import { DlInput } from "../components/input";
import { useNavigate } from "react-router-dom";
import userService from "../services/userService";

export function ForgotPasswordScreen() {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required("Champ requis")
        .email("Veuillez écrire votre email correctement"),
    }),
    onSubmit,
  });

  async function onSubmit() {
    setLoading(true);
    const data: any = formik.values;
    await userService
      .forgotPassword(data)
      .then(() => {})
      .catch(() => {});
    notification.info({
      message: "Demande de rénitialisation de compte",
      description: `Un message a été envoyé à ${formik.values.email} afin de vous permettre de réinitialiser votre mot de passe.`,
    });
    setOpen(false);
    setLoading(false);
  }

  function goToLogin() {
    navigate(`/`);
  }
  return (
    <Layout
      className="yc-bg-home"
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{ minWidth: 500, padding: 0, background: "#fff" }}
        className="yc-card"
      >
        <div
          style={{ width: "100%", textAlign: "center", padding: "10px 0 5px" }}
        >
          <img
            src={require("../assets/images/logo.png")}
            style={{ textAlign: "center", width: 100 }}
          />
        </div>
        <p style={{ textAlign: "center" }}>
          {`Vous avez oublié votre mot de passe veuillez le réinitialiser.`}
        </p>
        <div style={{ padding: "5px 15px 5px" }}>
          <div style={{ paddingTop: 5 }}>
            <DlInput
              placeholder="your@domain.com"
              type="email"
              formik={formik}
              name="email"
              label="E-mail"
              enabled={open}
            />
          </div>
          {!open && (
            <Alert
              type="info"
              message={`Un message a été envoyé à ${formik.values.email} afin de vous permettre de réinitialiser votre mot de passe.`}
            />
          )}
        </div>
        <div style={{ textAlign: "center" }}>
          <Button type="link" onClick={goToLogin}>
            {`Se connecter`}
          </Button>
        </div>
        <Row
          style={{
            background: "#f1f1f1",
            padding: "15px 10px",
            borderBottomLeftRadius: 10,
            WebkitBorderBottomRightRadius: 10,
          }}
          justify="end"
        >
          <Row
            gutter={4}
            align="middle"
            style={{ display: !open ? "none" : undefined }}
          >
            <Col>
              <Button
                disabled={loading}
                loading={loading}
                onClick={() => formik.handleSubmit()}
                type="primary"
              >
                {`Réinitialiser`}
              </Button>
            </Col>
          </Row>
        </Row>
      </div>
    </Layout>
  );
}
