import { MoreHoriz, MoreVert } from "@material-ui/icons";
import {
  Button,
  Col,
  Descriptions,
  Layout,
  List,
  message,
  notification,
  Popover,
  Row,
  Skeleton,
  Table,
  Tabs,
  Upload,
  UploadProps,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { isEmpty } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { RenderGroup } from "../components/renderGroup";
import fileService from "../services/fileService";
import lessonService from "../services/lessonService";
import subjectService from "../services/subjectService";
import subjectHelper from "../utils/helpers/subjectHelper";
import { getUid } from "../utils/text";
import { BranchSubject } from "./subject/branchs";
import { SubjectChapters } from "./subject/chapters";
import { CreateBranch } from "./subject/createBranch";
import { CreateChapiter } from "./subject/createChapiter";
import { OtherSubjects } from "./subject/others";
import { PresentationSubject } from "./subject/presentation";
import { CreateForecast } from "./forecast/create";
import { GoToForecast } from "./subject/goToForecast";
import { BreadcrumbComponent } from "../components/breadcrumb";

interface PopoverInterface {
  uid: string;
}

export function SubjectScreen() {
  const [subject, setSubject] = React.useState<any>({});
  const [subjects, setSubjects] = React.useState<any[]>([]);
  const [lessons, setLessons] = React.useState<any[]>([]);
  const [branchs, setBranchs] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const params = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    init();
  }, [params]);

  React.useEffect(() => {
    const id = params.id;
    subjectService
      .getByKey(`level=${subject?.level?.uid}&limit=1000&order[title]=asc`)
      .then((response) => {
        let data = subjectService.getData(response);
        data = data.filter((p) => p.uid != id);
        setSubjects(data);
      })
      .catch(() => {});
  }, [params, subject]);

  async function init() {
    const id: string = params.id as string;
    setLoading(true);
    await subjectService
      .get(id)
      .then((response) => {
        setSubject(response.data);
      })
      .catch(() => {});
    subjectHelper.getChapters(id, dispatch);
    await lessonService
      .getByKey(`subject=${id}`)
      .then((response) => {
        const data = lessonService.getData(response);
        data.map((p) => {
          p.file = p.files.length;
          p.videos = p.files.filter((x: any) =>
            x?.typeMime.includes("video")
          ).length;
          p.doc = p.files.filter((x: any) =>
            x?.typeMime.includes("pdf")
          ).length;
          p.action = getUid(p["@id"]);
        });
        setLessons(data);
      })
      .catch(() => {});
    setLoading(false);
    const elements = await subjectHelper.getBranchs(id);
    setBranchs(elements);
  }

  async function handleSaveFile(info: any, uid: string) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (!isEmpty(info.file.originFileObj)) {
      const data = new FormData();
      data.append("lesson", uid);
      data.append("file", info.file.originFileObj);
      setLoading(true);
      await fileService
        .store(data)
        .then(async () => {
          notification.info({ message: "Fichier ajouté" });
          await init();
        })
        .catch((reason) => {
          notification.error({ message: "echec" });
          console.log(reason.response);
        });
      setLoading(false);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  const PopoverMenu = (props: PopoverInterface) => {
    const { uid } = props;
    return (
      <Row style={{ flexDirection: "column" }}>
        <Col flex={1}>
          <Button type="text" className="yc-btn-left">
            <Upload
              onChange={(info) => handleSaveFile(info, uid)}
              multiple={false}
              accept="*"
            >
              Ajouter une vidéo
            </Upload>
          </Button>
        </Col>
        <Col flex={1}>
          <Button type="text" className="yc-btn-left">
            Ajouter un pdf
          </Button>
        </Col>
        <Col flex={1}>
          <Button type="text" className="yc-btn-left">
            Voir la leçon
          </Button>
        </Col>
      </Row>
    );
  };

  function initChapiters() {
    subjectHelper.getChapters(params.id as string, dispatch);
  }
  async function initBranchs() {
    const elements = await subjectHelper.getBranchs(params.id as string);
    setBranchs(elements);
  }

  return (
    <Layout>
      {!loading && (
        <React.Fragment>
          {/*<BreadcrumbComponent title={subject.title}>
          </BreadcrumbComponent>*/}
          <PageHeader
            onBack={() => window.history.back()}
            title={subject.title}
            extra={[
              <Button type="primary">
                <Link to={`/admin/lessons/${params.id}/create`}>
                  Ajouter une leçon
                </Link>
              </Button>,
              <CreateChapiter id={params.id} initFunction={initChapiters} />,
              <CreateBranch id={params.id} initFunction={initBranchs} />,
              // <CreateForecast
              //   //setVisible={setVisible}
              //   //visible={visible}
              //   subject={subject ?? {}}
              //   item={subject}
              //   //init={getForecats}
              //   branchs={branchs}
              // />,
              // <Button type="ghost">
              //   <Link to={`/admin/subjects/${params.id}/forecats`}>
              //     {`Allez au prévisions`}
              //   </Link>
              // </Button>,
              <GoToForecast buttonType="default" item={subject ?? {}} />,
            ]}
          >
            <Descriptions size="small" column={2}>
              <Descriptions.Item label="Niveau">
                {subject?.level?.title}
              </Descriptions.Item>
              <Descriptions.Item label="Groupement du niveau">
                <RenderGroup group={subject?.level?.groups} />
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
          <Tabs animated centered type="card">
            <Tabs.TabPane tab="Présentation" key="0">
              <PresentationSubject subject={subject} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Les leçons" key="1">
              <Table
                dataSource={lessons}
                loading={loading}
                className="yc-table"
                columns={[
                  {
                    title: "Titre de la leçon",
                    align: "center",
                    dataIndex: "title",
                  },
                  {
                    title: "Tous les fichiers",
                    align: "center",
                    dataIndex: "file",
                  },
                  {
                    title: "Documents",
                    align: "center",
                    dataIndex: "doc",
                  },
                  {
                    title: "Vidéos",
                    align: "center",
                    dataIndex: "videos",
                  },
                  {
                    title: "",
                    dataIndex: "action",
                    width: 150,
                    render: (value) => (
                      <Popover
                        title="Menu"
                        content={<PopoverMenu uid={value} />}
                      >
                        <Button type="dashed" shape="circle">
                          <MoreVert />
                        </Button>
                      </Popover>
                    ),
                  },
                ]}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Les chapitres" key="2">
              <SubjectChapters />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Les sous-branches" key="3">
              <BranchSubject items={branchs} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Les autres cours" key="4">
              <OtherSubjects items={subjects} />
            </Tabs.TabPane>
          </Tabs>
        </React.Fragment>
      )}
      {loading && (
        <React.Fragment>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </React.Fragment>
      )}
    </Layout>
  );
}
