import { Col, Layout, Row } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { Route, Routes, useLocation } from "react-router";
import { AdminNavbar } from "../components/navigation/adminNavbar";
import { SiderAdmin } from "../components/navigation/siderAdmin";
import routes from "../routes";
import { getToken } from "../utils/helpers/title";
import "./admin.scss";
import { NotFoundPage } from "./404";
import { YecProSidebar } from "../components/navigation/pro-sidebar";
import userHelper from "../utils/helpers/userHelper";
import store, { RootState } from "../redux/store";
import { setUserProfile } from "../redux/userReducer";
import { useSelector } from "react-redux";

/*const getRoutes = (routes = []) => {
  return routes
    .filter((p) => p.component != null)
    .map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.path} element={<prop.component />} key={key} />
        );
      } else {
        return null;
      }
    });
};*/

function AdminLayout() {
  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(false);
  const [rtl, setRtl] = React.useState(false);
  const [hasImage, setHasImage] = React.useState(false);
  const [theme, setTheme] = React.useState<"light" | "dark">("light");
  const profile = useSelector((state: RootState) => state.user.profile);
  const [roles, setRoles] = React.useState<string[]>([]);

  const onProfile = React.useCallback(() => {
    setRoles(profile?.auth?.roles ?? []);
  }, [profile]);
  React.useEffect(() => {
    onProfile();
  }, [onProfile]);
  const router = useLocation();

  // handle on RTL change event
  const handleRTLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRtl(e.target.checked);
  };

  // handle on theme change event
  const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTheme(e.target.checked ? "dark" : "light");
  };

  // handle on image change event
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasImage(e.target.checked);
  };
  const token = getToken();
  if (isEmpty(token)) {
    window.location.href = "/";
  }

  document.body.style.background = "#EDF2F8";

  function handleCollapse() {
    if (broken) {
      setToggled(true);
    } else {
      setCollapsed(!collapsed);
    }
  }

  const onInit = React.useCallback(async () => {
    const user = await userHelper.getData();
    store.dispatch(setUserProfile(user));
  }, []);

  React.useEffect(() => {
    onInit();
  }, []);

  return (
    <React.Fragment>
      <AdminNavbar
        collapsed={broken ? toggled : collapsed}
        onCollapse={handleCollapse}
      />
      <YecProSidebar
        onClose={() => setToggled(false)}
        setBroken={setBroken}
        collapsed={collapsed}
        rtl={rtl}
        theme={theme}
        toggled={toggled}
        hasImage={hasImage}
      />
      <Layout.Content
        id="primary"
        style={{ background: "#fff", padding: 0, overflowX: "hidden" }}
      >
        <Layout
          className="yc_container_main"
          style={{
            paddingLeft: !collapsed && !broken ? 260 : broken ? 10 : 90,
          }}
        >
          <Row gutter={16} wrap={false} className="yc_main">
            <Col flex="1">
              <div className="bg-light">
                <Routes>
                  {routes
                    .filter(
                      (p) =>
                        p.layout === "/admin" &&
                        p.roles?.some((role) => roles.includes(role))
                    )
                    .map((p, index) => {
                      if ((p.items?.length ?? 0) === 0) {
                        if (typeof p.component !== "undefined") {
                          return (
                            <Route
                              path={`${p.path}`}
                              element={<p.component />}
                              key={`${p.name}__${index}`}
                            />
                          );
                        } else {
                          return null;
                        }
                      } else {
                        return (
                          <>
                            {p.items
                              ?.filter((item) =>
                                item.roles?.some((role) => roles.includes(role))
                              )
                              .map((x, j) => {
                                if (typeof x.component !== "undefined") {
                                  return (
                                    <Route
                                      path={`${x.path}`}
                                      element={<x.component />}
                                      key={`${x.name}__${index}__${j}`}
                                    />
                                  );
                                } else {
                                  return null;
                                }
                              })}
                          </>
                        );
                      }
                    })}
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </div>
            </Col>
          </Row>
        </Layout>
      </Layout.Content>
    </React.Fragment>
  );
}

export { AdminLayout };
