import { Card } from "antd";
import moment from "moment";
import React from "react";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { colorPrimary } from "../../utils/theme/color";

export function LastStudentSaving() {
  const charts = useSelector((state: RootState) => state.user.charts);
  const [labels, setLabels] = React.useState<string[]>([]);
  const [series, setSeries] = React.useState<number[]>([]);

  async function init() {
    const months: any[] = [];
    for (let i = 0; i < 12; i++) {
      months.push(moment().month(i).format("MMMM")); // Format du mois complet (par exemple, janvier, février, etc.)
    }
    const list = charts.filter(
      (p) =>
        p.type === "month_evolution" &&
        ["student", "teacher"].findIndex((x) => x === p.category) !== -1
    );
    const items: any[] = [];
    list.forEach((l) => {
      const data: any[] = [];
      const elements: any[] = l.data ?? [];
      months.forEach((m, i) => {
        const v = elements.find((e) => e.month === i);
        data.push(v?.count ?? 0);
      });
      items.push({ data, name: l.label, title: l.label });
    });
    setLabels(months);
    setSeries(items);
  }

  const onInit = React.useCallback(() => {
    init();
  }, [charts]);

  React.useEffect(() => {
    onInit();
  }, [charts]);

  return (
    <div>
      <Card className="card" style={{ marginTop: 10, marginBottom: 10 }}>
        <div id="chart">
          <ReactApexChart
            options={{
              chart: {
                id: "realtime",
                height: 350,
                type: "line",
                animations: {
                  enabled: true,
                  //easing: 'linear',
                  dynamicAnimation: {
                    speed: 1000,
                  },
                },
                toolbar: {
                  show: false,
                },
                zoom: {
                  enabled: false,
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
                width: 1,
                //colors: [colorPrimary, "red", "green"],
              },
              title: {
                text: "Les enregistrements au fil de mois",
                align: "left",
              },
              markers: {
                size: 0,
              },
              xaxis: {
                //type: 'datetime',
                //range: XAXISRANGE,
                categories: labels,
              },
            }}
            series={series}
            type="line"
            height={450}
          />
        </div>
        <div id="html-dist"></div>
      </Card>
    </div>
  );
}
