import { MoreOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Layout,
  List,
  Popover,
  Row,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { isEmpty, upperFirst } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RenderGroup } from "../components/renderGroup";
import { setSchoolFilters } from "../redux/levelSchoolReducer";
import { RootState } from "../redux/store";
import groupService from "../services/groupService";
import levelService from "../services/levelService";
import { getUid } from "../utils/text";
import { CreateLevel } from "./level/create";

export function getLevel(value: any) {
  let response = "";
  const group = value.groups;
  if (!isEmpty(group)) {
    const values = ["faculty", "department", "option"];
    if (values.findIndex((p) => p == group.type) != -1) {
      response = value.title;
    } else {
      response = `${value.promotion} ${
        value.promotion > 1 ? "ème" : "ère"
      } année `;
      if (["section", "option"].findIndex((p) => p == group.type) === -1) {
        response += `${group.name}`;
      }
    }
  }
  return response;
}

export function getGroupLevel(value: any) {
  let response = [];
  const group = value.groups;
  if (!isEmpty(group)) {
    response.push({
      type: group.type,
      name: group.name,
    });
  }
  return response;
}

export function getLevelData(data: any[]) {
  data.map((p) => {
    p.level = getLevel(p);
    p.group = p.groups;
    p.subject = p.subjects.length;
  });
  return data;
}

function groupColor(type: any) {
  let response = "default";
  switch (type) {
    case "faculty":
      response = "blue";
      break;
    case "cycle":
      response = "blue";
      break;
    case "department":
      response = "cyan";
      break;
    case "section":
      response = "gold";
      break;
    case "option":
      response = "green";
      break;

    default:
      break;
  }
  return response;
}

interface levelInterface {
  levels: any[];
  loading: boolean;
  init?: any;
  type: "school" | "university";
  groupLevel1?: any[];
  setGroupLevel1?: any;
  groupLevel2?: any[];
  setGroupLevel2?: any;
  groupLevel3?: any[];
  setGroupLevel3?: any;
}

interface groupInterface {
  items: any[];
  type: "school" | "university";
  level: 1 | 2 | 3;
  setSubGroups?: any;
}

function GroupChoice(props: groupInterface) {
  const { items, level, type, setSubGroups } = props;
  const [checkeds, setCheckeds] = React.useState<string[]>([]);
  const levelFilters = useSelector((state: RootState) => state.school.filters);
  const dispatch = useDispatch();

  async function handleCheck(uid: string) {
    let elements = checkeds;
    const p = elements.findIndex((x) => x === uid);
    if (p === -1) {
      elements.push(uid);
    } else {
      elements = elements.filter((x) => x !== uid);
    }
    setCheckeds(elements);
    const filters = levelFilters.filter((p) => p.item !== "groups[]");
    elements.forEach((x) => {
      filters.push({ item: "groups[]", value: x });
    });
    dispatch(setSchoolFilters(filters));
    if (typeof setSubGroups === "function") {
      let query = ``;
      elements.forEach((x) => {
        query += `parent[]=${x}&`;
      });
      if (isEmpty(query)) {
        setSubGroups([]);
      } else {
        await groupService
          .getByKey(query)
          .then((response) => {
            const data = groupService.getData(response);
            setSubGroups(data);
          })
          .catch(() => {});
      }
    }
  }

  const renderItem = (item: any) => {
    return (
      <div>
        <Checkbox
          checked={checkeds.findIndex((p) => p === item.uid) !== -1}
          onChange={() => handleCheck(item.uid)}
        >
          {upperFirst(item.name)}
        </Checkbox>
      </div>
    );
  };
  return (
    <div>
      <List dataSource={items} renderItem={renderItem} />
    </div>
  );
}

export function LevelScreen(props: levelInterface) {
  const {
    levels,
    loading,
    init,
    type,
    groupLevel1,
    groupLevel2,
    groupLevel3,
    setGroupLevel1,
    setGroupLevel2,
    setGroupLevel3,
  } = props;

  return (
    <React.Fragment>
      <Layout className="box">
        <PageHeader
          title={`${
            type === "school" ? "Toutes les classes" : "Toutes les promotions"
          }`}
          extra={[<CreateLevel key="create_level" init={init} type={type} />]}
          style={{ background: "#fff" }}
        >
          <Row
            style={{ width: "100%" }}
            align="middle"
            justify="center"
            gutter={8}
          >
            <Col>
              <Popover
                content={
                  <GroupChoice
                    items={groupLevel1 ?? []}
                    type={type}
                    level={1}
                    setSubGroups={setGroupLevel2}
                  />
                }
              >
                <Button>
                  {`Filtrer par ${type === "school" ? "cycle" : "faculté"}`}
                </Button>
              </Popover>
            </Col>
            <Col>
              <Popover
                content={
                  <GroupChoice
                    items={groupLevel2 ?? []}
                    type={type}
                    level={2}
                    setSubGroups={setGroupLevel3}
                  />
                }
              >
                <Button>
                  {`Filtrer par ${
                    type === "school" ? "section" : "departement"
                  }`}
                </Button>
              </Popover>
            </Col>
            <Col>
              <Popover
                content={
                  <GroupChoice
                    items={groupLevel3 ?? []}
                    type={type}
                    level={3}
                  />
                }
              >
                <Button>{`Filtrer par option`}</Button>
              </Popover>
            </Col>
          </Row>
        </PageHeader>
        <Table
          dataSource={levels}
          loading={loading}
          className="yc-table"
          columns={[
            {
              dataIndex: "level",
              title: "Niveau",
              width: 200,
            },
            {
              dataIndex: "group",
              title: "Groupement",
              width: 200,
              render: (value) => {
                return <RenderGroup group={value} />;
              },
            },
            {
              dataIndex: "subject",
              title: "Matières",
            },
            {
              dataIndex: "registers",
              title: "Etudiants",
              render: (value) => {
                const list = value;
                if (isEmpty(list)) {
                  return <div>Aucun étudiant</div>;
                }
                if (list.length === 0) {
                  return <div>Aucun étudiant</div>;
                }
                return (
                  <Avatar.Group>
                    {list.map((p: any, index: number) => {
                      if (index < 5) {
                        return (
                          <Avatar
                            style={{ background: "#010101" }}
                            src={
                              !isEmpty(p.student?.users?.photo) ? (
                                <img src={p.student?.users?.photo} />
                              ) : null
                            }
                          >
                            <Tooltip
                              title={`${p.student?.users?.firstName} ${p.student?.users?.lastName}`}
                            >
                              {isEmpty(p.student?.users?.photo) && (
                                <UserOutlined rev="" />
                              )}
                            </Tooltip>
                          </Avatar>
                        );
                      }
                    })}
                    {list.length > 5 && (
                      <Avatar>
                        <PlusOutlined rev="" />
                      </Avatar>
                    )}
                  </Avatar.Group>
                );
              },
            },
            {
              dataIndex: "@id",
              title: "",
              render: (value) => (
                <Button shape="circle" type="primary">
                  <Link to={`/admin/levels/${getUid(value)}/view`}>
                    <MoreOutlined rev="" />
                  </Link>
                </Button>
              ),
            },
          ]}
        />
      </Layout>
    </React.Fragment>
  );
}
