import { Avatar, Modal, Tabs } from "antd";
import React from "react";
import commitmentService from "../../services/commitmentService";
import { getUid } from "../../utils/text";
import { LevelList } from "./levelList";
import { SearchLevel } from "./searcLevel";

interface createTeacherLevelInterface {
  user: any;
  visible: boolean;
  setVisible: any;
  onReload?: any;
}

export function CreateTeacherLevel(props: createTeacherLevelInterface) {
  const { user, setVisible, visible, onReload } = props;
  const [items, setItems] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [tab, setTab] = React.useState("0");

  function handleClose() {
    if (!loading) setVisible(false);
  }

  async function handleSave() {
    setLoading(true);
    await Promise.all(
      items.map(async (p: any) => {
        const data = {
          teacher: `${getUid(user.teacher.uid)}`,
          level: `${getUid(p.uid)}`,
          status: `in_progress`,
        };
        await setCommit(data);
      })
    );
    setVisible(false);
    setLoading(false);
    if (typeof onReload === "function") {
      onReload();
    }
    setItems([]);
    setTab("0");
  }

  async function setCommit(data: any) {
    let response = false;
    await commitmentService
      .store(data)
      .then(() => {
        response = true;
      })
      .catch((reason) => {
        console.warn(reason);
      });
    return response;
  }

  function getCount(list: any[]): string {
    let response = "0";
    const len = list.length;
    if (len > 99) {
      response = "+99";
    } else {
      response = `${len}`;
    }
    return response;
  }

  return (
    <React.Fragment>
      <Modal
        visible={visible}
        centered
        confirmLoading={loading}
        okButtonProps={{ disabled: items.length === 0 || loading }}
        cancelButtonProps={{ disabled: loading }}
        onCancel={handleClose}
        okText="Valider"
        cancelText="Annuler"
        title="Choix des classes"
        onOk={handleSave}
      >
        <div>
          <Tabs onChange={setTab} activeKey={tab}>
            <Tabs.TabPane key="0" animated tab="Recherche de classe">
              <SearchLevel list={items} setList={setItems} />
            </Tabs.TabPane>
            <Tabs.TabPane
              key="1"
              animated
              tab={
                <div style={{ padding: "5px" }}>
                  {`Votre séléction`}
                  <Avatar size={"small"}>{getCount(items)}</Avatar>
                </div>
              }
            >
              <LevelList
                uid={user?.teacher?.uid}
                list={items}
                setList={setItems}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Modal>
    </React.Fragment>
  );
}
